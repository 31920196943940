// ==================================================
// Mixin
// ==================================================

//  media query
//----------------------------------------------

// pc-tb切り替え
@use 'sass:math';

$bp-tb: 1024px;
// pc-sp切り替え
$bp-md: 768px;

@mixin media-all-pc {
  @media all and (min-width: #{$bp-md + 1}) {
    @content;
  }
}

@mixin media-only-screen-pc {
  @media screen and (min-width: #{$bp-md + 1}) {
    @content;
  }
}

@mixin media-all-tb {
  @media all and (min-width: 769px) and (max-width: #{$bp-tb}) {
    @content;
  }
}

@mixin media-all-sp {
  @media all and (max-width: #{$bp-md}) {
    @content;
  }
}

@mixin media-screen-sp {
  @media screen and (max-width: #{$bp-md}) {
    @content;
  }
}

// SP vw calculator
@function vw-value($pxValue) {
  @return (math.div($pxValue, 750) * 100) + vw;
}

//  Color
// ----------------------------------------------

// MH Key color
$color-red: #ff0000;

// Construction pink
$color-pink: #f9e5e5;

// Handover lightBlue
$color-lightBlue: #d6eefc;

// UI color
$color-blue: #0079ff;

// Main background color
$color-lightGray: #f9f9f9;

// Main border color
$color-mediumGray: #d6d6d6;

// SwitchToggle UI color
$color-toggleGreen: #33e03a;

// Multiple checkers color
$color-check-red: #c97179;
$color-check-yellow: #e6c671;
$color-check-green: #9cc06c;
$color-check-blue: #7cb5d1;
$color-check-purple: #cd8eb4;

// Furniture__note の height（高さ）を管理
// 高さが他のところにも影響するため、変数管理しています
// 運用方法: 文言などの変更でFurniture__noteの高さが変更される場合には、変数を更新する
$height_pc_Furniture__note: 8rem;
$height_sp_Furniture__note: 9.6rem;
