@use '../../sass/foundation/mixin';

// ==================================================
// Components
// ==================================================

// container
//---------------------------------------

.wrap {
  @include mixin.media-all-pc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  @include mixin.media-all-sp {
    height: 100%;
  }
}

.content-wrap {
  @include mixin.media-all-pc {
    flex-grow: 2;
    flex-shrink: 0;
    height: 100%;
  }

  @include mixin.media-screen-sp {
    overflow: hidden;
    transition: all 0.4s;
  }

  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  &.-open {
    margin-left: 0 !important;

    .Nav__root-sp.-slide {
      margin-left: 0 !important;
    }
  }
}

.container {
  @include mixin.media-all-pc {
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  @include mixin.media-all-sp {
    position: relative;
    height: 100vh;
    padding-top: 4.4rem;
    padding-bottom: 4.8rem;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  @media print {
    overflow: visible;
  }

  &.-with-bar {
    @include mixin.media-all-pc {
      padding-top: 6rem;
    }
  }

  &.-with-tab {
    @include mixin.media-all-pc {
      padding-top: 10rem;
    }

    @include mixin.media-all-sp {
      padding-top: 8.2rem;
      padding-bottom: 4.8rem;
    }
  }
}

.main-content {
  @include mixin.media-all-sp {
    width: 100vw;
    height: 100vh;
    flex-grow: 0;
    flex-shrink: 0;
  }

  position: relative;
  width: 100%;

  &--without-headparts {
    padding-top: 0 !important;
  }
}
