#column_article.article_21 {
  .article__content-two-col.title-area .article__content-two-col-item {
    width: 64%;
  }

  .article__image-four-col .article__image-four-col-item:nth-of-type(3) {
    width: 20%;
  }

  .article__content-two-col
    .article__content-two-col-item.article__layout:nth-of-type(1) {
    width: 80%;
  }

  .article__content-two-col
    .article__content-two-col-item.article__layout:nth-of-type(2) {
    width: 20%;
  }

  .article__content-two-col
    .article__content-two-col-item.article__original-size:nth-of-type(1) {
    width: 52%;
  }

  .article__content-two-col
    .article__content-two-col-item.article__original-size:nth-of-type(2) {
    width: 43%;
  }

  .article__column-content p.article__figurement.row-2 span {
    word-break: break-all;
  }

  .article__figurement-row {
    min-height: 160px;
  }

  .article__column-content .article__image-three-col-item span {
    font-size: 21px;
  }

  .article__column-content p.article__slm {
    font-size: 16px;
    line-height: 30px;
  }

  .mb-20-pc {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    .article__figurement-row {
      min-height: 80px;
    }

    .article__image-four-col .article__image-four-col-item:nth-of-type(3) {
      width: 45%;
    }

    .mt-10 {
      margin-top: 10px;
    }

    .mb-10 {
      margin-bottom: 10px;
    }

    .article__image-container p {
      text-align: left;
    }

    .article__column-content p {
      margin: 0px 0 5px;
    }

    .article__content-two-col
      .article__content-two-col-item.article__layout:nth-of-type(1) {
      width: 70%;
    }

    .article__content-two-col
      .article__content-two-col-item.article__layout:nth-of-type(2) {
      width: 30%;
    }

    .article__column-content .article__image-three-col-item span {
      font-size: 0.5rem;
    }

    .article__column-content p.article__slm {
      font-size: 11px;
      line-height: 16px;
    }

    .mb-20-pc {
      margin-bottom: 0px;
    }
  }
}
