#column_article.article_43 {
  .article__column-content h3 {
    font-size: 22px;
    padding: 0 0 0 40px;
  }

  .article__column-content p {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 768px) {
    .article__column-content h3 {
      font-size: 16px;
      padding: 0 0 0 35px;
      text-align: left;
      min-height: 40px;
    }

    .article__column-content p {
      font-size: 13px;
      line-height: 21px;
    }
  }
}
