#column_article.article_35 {
  .article__column-content h3 {
    padding: 0 0 0 40px;
    font-size: 24px;
  }

  .article__column-title p {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    .article__column-title p {
      font-size: 14px;
    }

    .article__column-content h3 {
      padding: 0 0 0 35px;
      font-size: 14px;
    }

    .article__content-two-col
      .article__content-two-col-item.article__wid-sp100 {
      width: 100%;
      text-align: left;
    }

    a {
      font-size: 14px;
    }
  }
}
