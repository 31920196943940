#column_article.article_69 {
  .article__content-two-col .article__content-two-col-item {
    width: 60%;
  }

  .article__content-two-col .article__content-two-col-item.article__right-img {
    width: 40%;
  }

  .article__column-title h2 span {
    bottom: 5px;
    left: 70px;
    font-size: 19px;
    color: #000;
    margin-left: 20px;
  }

  @media (max-width: 768px) {
    .article__content-two-col .article__content-two-col-item {
      width: 100%;
      text-align: center;
    }

    .article__content-two-col
      .article__content-two-col-item.article__right-img {
      width: 100%;
      text-align: center;
    }

    .article__column-title h2 span {
      left: 45px;
      font-size: 9px;
    }

    .sp_mt20 {
      margin-top: 20px;
    }
  }
}
