#column_article.article_17 {
  .article__column-content h3 {
    margin: 0 0 1rem;
  }

  .article__image-container {
    text-align: left;
    margin: 30px auto 0;
  }

  @media (max-width: 768px) {
    .article__column-content h3 {
      margin: 1rem 0;
    }

    .article__image-container {
      margin: 20px auto;
    }

    .article__content-two-col .article__content-two-col-item {
      width: 100%;
    }
  }
}
