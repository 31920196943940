@use '../../../sass/foundation/mixin';

// ==================================================
// Project
// ==================================================

// Check_item
// ==========================================================================

.CheckItem {
  @include mixin.media-all-pc {
    padding-bottom: 6rem;
  }

  @include mixin.media-all-sp {
    padding-bottom: 3rem;
  }

  margin-top: -0.2rem;

  .CheckItem__list-header {
    height: 3.2rem;
    @include mixin.media-all-pc {
      width: 62rem;
    }
    margin-left: auto;
    margin-right: auto;
    padding-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid #d6d6d6;
    position: sticky;
    top: -0.2rem;
    background-color: #fff;
    z-index: 2;
    div {
      margin-left: 1.2rem;
      cursor: pointer;
      font-size: 1.3rem;
      font-weight: 500;
      @include mixin.media-all-pc {
        font-size: 1.6rem;
      }
    }
  }

  @at-root {
    .CheckItem__list {
      @include mixin.media-all-pc {
        width: 62rem;
      }

      margin-right: auto;
      margin-left: auto;

      li {
        .CheckItem__list-item-inner {
          @include mixin.media-all-pc {
            min-height: 6.4rem;
            padding-top: 2rem;
            padding-bottom: 1.7rem;
          }

          @include mixin.media-all-sp {
            min-height: mixin.vw-value(120);
            padding-top: mixin.vw-value(24);
            padding-bottom: mixin.vw-value(20);
          }

          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          border-bottom: 1px solid mixin.$color-mediumGray;
          line-height: 1.5;
          cursor: pointer;

          &::before {
            @include mixin.media-all-pc {
              right: 2rem;
              width: 1rem;
              height: 2rem;
            }
            @include mixin.media-all-sp {
              right: mixin.vw-value(38);
              width: mixin.vw-value(14);
              height: mixin.vw-value(28);
            }

            content: '';
            position: absolute;
            background-image: url('../../../../public/assets/img/common/icon/arrow_right.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            transform: rotate(90deg);
          }
        }

        &.-open {
          .CheckItem__list-item-inner::before {
            transform: rotate(270deg);
          }
        }

        .CheckItem__list-label {
          @include mixin.media-all-pc {
            max-width: 46rem;
            margin-left: 1rem;
            font-size: 1.8rem;
          }

          @include mixin.media-all-sp {
            max-width: mixin.vw-value(500);
            margin-left: mixin.vw-value(26);
            font-size: mixin.vw-value(26);
          }

          width: 100%;
          font-weight: 700;
          word-wrap: break-word;
          overflow-wrap: break-word;
        }

        .CheckItem__list-number {
          @include mixin.media-all-pc {
            margin-right: 7.5rem;
            font-size: 1.7rem;
          }

          @include mixin.media-all-sp {
            margin-right: mixin.vw-value(111);
            font-size: mixin.vw-value(24);
          }
        }
      }
    }

    .CheckItem__list-detail {
      @include mixin.media-all-pc {
        background-color: mixin.$color-lightGray;
      }

      dt {
        @include mixin.media-all-pc {
          padding-top: 0.5rem;
          padding-right: 1.5rem;
          padding-left: 1.5rem;
          padding-bottom: 0.4rem;
          font-size: 1.5rem;
        }

        @include mixin.media-all-sp {
          padding-top: mixin.vw-value(10);
          padding-right: 1em;
          padding-left: 1em;
          padding-bottom: mixin.vw-value(11);
          font-size: 1.4rem;
        }

        background-color: #bcbcbc;
        color: #fff;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }

      dd {
        @include mixin.media-all-pc {
          min-height: 6.4rem;
          padding-top: 1.2rem;
          padding-left: 5rem;
          padding-bottom: 1.2rem;
        }

        @include mixin.media-all-sp {
          min-height: mixin.vw-value(120);
          margin-left: mixin.vw-value(33);
          padding-top: mixin.vw-value(30);
          padding-left: mixin.vw-value(52);
          padding-bottom: mixin.vw-value(30);
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;

        &.-construction {
          border-top: 1px solid mixin.$color-mediumGray;
        }

        .CheckItem__list-check-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          width: 100%;

          @include mixin.media-all-pc {
            min-height: 5rem;
          }
          @include mixin.media-all-sp {
            margin-top: mixin.vw-value(10);
          }

          & + .CheckItem__list-check-item {
            margin-top: 1.7rem;
          }

          .CheckItem__list-check-inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          }

          &.-checked {
            &::before {
              @include mixin.media-all-pc {
                left: -3.5rem;
                width: 2.2rem;
                height: 1.4rem;
              }

              @include mixin.media-all-sp {
                left: -6.8vw;
                width: mixin.vw-value(28);
                height: mixin.vw-value(22);
              }

              content: '';
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              background-image: url('../../../../public/assets/img/common/icon/check_black.svg');
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }

            .CheckItem__list-datail-name {
              @include mixin.media-all-pc {
                max-width: 59%;
              }

              @include mixin.media-all-sp {
                width: 58%;
              }

              color: #bebebe;
              word-wrap: break-word;
              overflow-wrap: break-word;
            }

            .CheckItem__checked-household {
              color: #333333;
            }
            .CheckItem__checked-desc {
              color: #333333;
            }
          }

          &.-child {
            &::before {
              @include mixin.media-all-pc {
                left: -35px;
              }
              @include mixin.media-all-sp {
                left: -6.8vw;
              }
            }

            .CheckItem__list-datail-name {
              @include mixin.media-all-pc {
                margin-left: 3.2rem;
              }
              @include mixin.media-all-sp {
                width: 40%;
                margin-left: 3vw;
              }
            }
          }
        }

        .SheetView__list-check-desc-wrap {
          @include mixin.media-all-pc {
            align-items: flex-end;
            width: 19rem;
            margin-top: 0.6rem;
            margin-right: 2.2rem;
          }
          @include mixin.media-all-sp {
            align-items: flex-start;
            width: 24%;
            margin-top: mixin.vw-value(20);
            margin-right: mixin.vw-value(30);
          }

          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
          flex-shrink: 0;
          flex-grow: 0;

          &.-household-single {
            flex-direction: row-reverse;
            width: 18rem;
          }

          .CheckItem__checked-desc {
            @include mixin.media-all-pc {
              max-width: 19rem;
              font-size: 1.2rem;
            }

            @include mixin.media-all-sp {
              display: block;
              max-width: 24vw;
              font-size: mixin.vw-value(16);
            }

            text-align: right;
            line-height: 1.6;
          }

          .CheckItem__checked-household {
            @include mixin.media-all-pc {
              max-width: 19rem;
              top: -20px;
              font-size: 1.2rem;
            }

            @include mixin.media-all-sp {
              display: block;
              max-width: 24vw;
              top: -18px;
              left: 0;
              font-size: mixin.vw-value(16);
            }

            line-height: 1.6;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -webkit-text-overflow: ellipsis; /* Safari */
            -o-text-overflow: ellipsis; /* Opera */
          }
        }
      }

      .CheckItem__list-datail-name {
        @include mixin.media-all-pc {
          padding-right: 1.6em;
          font-size: 1.6rem;
        }

        @include mixin.media-all-sp {
          width: 68%;
          padding-right: 1em;
          font-size: 1.5rem;
        }

        flex-grow: 10;
        line-height: 1.6;
      }
    }
  }
}
