#column_article.article_55 {
  .article__column-content p {
    font-size: 21px;
  }

  @media (max-width: 768px) {
    .article__column-content p {
      font-size: 12px;
    }
  }
}
