@use '../../../sass/foundation/mixin';

// ==================================================
// Project
// ==================================================

// Home
// ==========================================================================
#Home {
  .Nav__root-pc {
    min-height: 138.6rem;
  }

  .main-content {
    @include mixin.media-all-pc {
      background-color: mixin.$color-lightGray;
    }
  }
}

.Home {
  @at-root {
    .Home__inner {
      @include mixin.media-all-pc {
        width: 62.4rem;
      }

      @include mixin.media-all-sp {
        width: mixin.vw-value(675);
      }

      margin-right: auto;
      margin-left: auto;
    }

    .Home__heading {
      @include mixin.media-all-pc {
        font-size: 1.4rem;
      }

      @include mixin.media-all-sp {
        font-size: mixin.vw-value(28);
      }

      display: flex;
      align-items: center;
      position: relative;
      padding-left: 4.2rem;
      font-weight: 700;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }

      &.-icon-document::before {
        width: 2.9rem;
        height: 2.1rem;
        margin-bottom: 0.4rem;
        background-image: url('../../../../public/assets/img/common/icon/read.svg');
      }

      &.-icon-check {
        margin-top: 2rem;

        &::before {
          width: 2rem;
          height: 1.9rem;
          margin-bottom: 0.4rem;
          background-image: url('../../../../public/assets/img/common/icon/check.svg');
        }
      }

      &.-icon-file::before {
        width: 2.3rem;
        height: 1.9rem;
        margin-bottom: 0.4rem;
        background-image: url('../../../../public/assets/img/common/icon/file.svg');
      }
    }

    // .Home__icon {
    //   &.-icon-point {
    //     width: 1.9rem;
    //     height: 2.4rem;
    //     background-image: url("../../../public/assets/img/common/icon/point.svg");
    //     background-size: 1.9rem;
    //     background-repeat: no-repeat;
    //     background-position: center center;
    //   }

    //   &.-icon-parking {
    //     width: 2.3rem;
    //     height: 2.3rem;
    //     background-image: url("../../../../public/assets/img/common/icon/parking.svg");
    //     background-size: 2.3rem;
    //     background-repeat: no-repeat;
    //     background-position: center center;
    //   }
    // }

    .Home__slide {
      @include mixin.media-all-pc {
        border-bottom: 1px solid mixin.$color-mediumGray;
      }

      @include mixin.media-all-sp {
      }
      background-color: #fff;

      .Home__slide-item {
        @include mixin.media-all-sp {
          padding-bottom: 5rem;
        }
        display: none;

        &.-active {
          display: block;
        }
      }

      .Home__slide-content {
        @include mixin.media-all-sp {
          padding-bottom: 1rem;
        }
        display: none;

        &.-active {
          display: block;
        }
      }
    }

    .Home__hero {
      @include mixin.media-all-pc {
        height: 33.6rem;
        padding-top: 2.5rem;
      }

      @include mixin.media-all-sp {
        padding-top: mixin.vw-value(25);
        padding-bottom: mixin.vw-value(25);
      }

      background-image: url('../../../../public/assets/img/home/ex_home_hero_01.jpg');
      background-size: cover;
      background-position: center center;
    }

    .Home__panel {
      @include mixin.media-all-pc {
        width: 30rem;
        height: 29rem;
        padding-top: 2rem;
        padding-right: 2rem;
        padding-left: 2rem;
        padding-bottom: 1.6rem;
        background-color: rgba(255, 255, 255, 0.85);
      }

      @include mixin.media-all-sp {
        width: mixin.vw-value(454);
        min-height: mixin.vw-value(444);
        padding-top: mixin.vw-value(25);
        padding-right: mixin.vw-value(20);
        padding-left: mixin.vw-value(20);
        padding-bottom: mixin.vw-value(20);
        background-color: rgba(255, 255, 255, 0.85);
      }

      @media print {
        background-color: rgba(255, 255, 255, 1);
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-right: auto;
      margin-left: auto;
      border-radius: 5px;

      h1 {
        @include mixin.media-all-pc {
          width: 80%;
          font-size: 2rem;
        }

        @include mixin.media-all-sp {
          width: 70%;
          font-size: mixin.vw-value(30);
        }

        text-align: center;
        font-weight: 700;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }

      .text-date {
        @include mixin.media-all-pc {
          margin-top: 0.8rem;
          font-size: 1.4rem;
        }

        @include mixin.media-all-sp {
          margin-top: mixin.vw-value(12);
          font-size: mixin.vw-value(22);
        }

        min-height: 1.1em;
      }

      .text-milestone {
        @include mixin.media-all-pc {
          margin-top: 0.4rem;
          font-size: 1.4rem;
        }

        @include mixin.media-all-sp {
          margin-top: mixin.vw-value(4);
          font-size: mixin.vw-value(22);
        }

        min-height: 1.1em;
        color: #666666;
        font-weight: 700;
      }

      .Home__panel-btn {
        @include mixin.media-all-pc {
          bottom: 1.5rem;
          right: 1.5rem;
          width: 4rem;
          height: 4rem;
          background-size: 2.6rem;
        }

        @include mixin.media-all-sp {
          bottom: mixin.vw-value(20);
          right: mixin.vw-value(21);
          width: mixin.vw-value(50);
          height: mixin.vw-value(50);
          background-size: mixin.vw-value(42);
        }

        position: absolute;
        background-image: url('../../../../public/assets/img/common/icon/reverse.svg');
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
      }

      .Home__panel-flow-btn {
        @include mixin.media-all-pc {
          top: 1.8rem;
          right: 1.6rem;
          width: 3.4rem;
          height: 4rem;
          background-size: 2.6rem;
        }

        @include mixin.media-all-sp {
          top: mixin.vw-value(20);
          right: mixin.vw-value(21);
          width: mixin.vw-value(50);
          height: mixin.vw-value(58);
          background-size: mixin.vw-value(42);
        }

        position: absolute;
        background-image: url('../../../../public/assets/img/common/icon/flow_list.svg');
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
      }

      .Home__panel-list {
        @include mixin.media-all-pc {
          padding-left: 3.8rem;
        }

        @include mixin.media-all-sp {
          padding-left: mixin.vw-value(76);
        }

        li {
          @include mixin.media-all-pc {
            font-size: 1.7rem;
          }

          @include mixin.media-all-sp {
            font-size: mixin.vw-value(26);
          }

          color: #c4c4c4;
          font-weight: 700;
          line-height: 1.5;

          & + li {
            margin-top: 0.6rem;
          }

          &.-current {
            color: mixin.$color-red;
          }
        }
      }

      .Home__hero-count {
        width: 48%;
        margin-top: 0.52rem;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          @include mixin.media-all-pc {
            font-size: 1.6rem;
          }

          @include mixin.media-all-sp {
            font-size: mixin.vw-value(25);
          }

          font-weight: 700;
        }

        .Home__hero-count-value {
          @include mixin.media-all-pc {
            font-size: 4rem;
          }

          @include mixin.media-all-sp {
            font-size: mixin.vw-value(60);
          }

          min-height: 1.2em;
          font-weight: normal;
        }
      }

      &.-reverse {
        position: relative;

        .Home__panel-img {
          height: 90%;
          @include mixin.media-all-pc {
            left: 6rem;
            width: 2.2rem;
          }

          @include mixin.media-all-sp {
            left: mixin.vw-value(84);
            width: mixin.vw-value(36);
          }

          position: absolute;
        }
      }

      .nonDisplay {
        display: none;
      }
    }

    .Home__hero-graph {
      @include mixin.media-all-pc {
        width: 12.4rem;
        height: 12.4rem;
        margin-top: 0.6rem;
        border: 4px solid #fac6bf;
      }

      @include mixin.media-all-sp {
        width: mixin.vw-value(188);
        height: mixin.vw-value(188);
        margin-top: mixin.vw-value(6);
        border: 3px solid #fac6bf;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: auto;
      margin-left: auto;
      border-radius: 50%;

      .Home__hero-graph-count {
        width: 8.4rem;
        margin-top: -1.4rem;
        margin-right: auto;
        margin-left: auto;
        line-height: 1.75em;

        .Home__hero-graph-label {
          @include mixin.media-all-pc {
            font-size: 1.6rem;
          }

          @include mixin.media-all-sp {
            font-size: mixin.vw-value(24);
          }

          font-weight: 700;
        }

        .Home__hero-graph-count-value {
          position: relative;

          @include mixin.media-all-pc {
            margin-top: 1.4rem;
            font-size: 3.2rem;
          }

          @include mixin.media-all-sp {
            margin-top: mixin.vw-value(24);
            font-size: mixin.vw-value(44);
          }

          &::after {
            content: '%';
            position: absolute;
            top: 0.6rem;
            right: -1.6rem;
            color: #333;
            font-size: 1.5rem;
          }
        }
      }
    }

    .Home__slide-label {
      @include mixin.media-all-pc {
        width: 62.4rem;
        padding-top: 2rem;
        padding-bottom: 0.7rem;
      }

      @include mixin.media-all-sp {
        width: mixin.vw-value(675);
        padding-top: mixin.vw-value(36);
        padding-bottom: mixin.vw-value(20);
      }

      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-right: auto;
      margin-left: auto;
      border-bottom: 1px solid mixin.$color-mediumGray;

      .Home__slide__btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 100%;
        box-sizing: content-box;

        .Home__slide__arrow {
          display: block;
          position: absolute;
          width: 1.1rem;
          height: 2rem;
          padding-top: 0.4rem;
          padding-right: 0.4rem;
          padding-left: 0.4rem;
          padding-bottom: 0.4rem;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;

          @include mixin.media-all-pc {
            width: 1.1rem;
            height: 2rem;
          }

          @include mixin.media-all-sp {
            width: mixin.vw-value(21);
            height: mixin.vw-value(39);
          }

          &.arrow-right {
            @include mixin.media-all-pc {
              right: 1.4rem;
            }
            @include mixin.media-all-sp {
              right: 0;
            }

            background-image: url('../../../../public/assets/img/common/icon/arrow_right.svg');
          }

          &.arrow-left {
            @include mixin.media-all-pc {
              left: 1.4rem;
            }
            @include mixin.media-all-sp {
              left: 0;
            }

            background-image: url('../../../../public/assets/img/common/icon/arrow_left.svg');
          }
        }
      }

      .Home__slide-label-text {
        @include mixin.media-all-pc {
          width: 84%;
        }

        @include mixin.media-all-sp {
          width: 80%;
        }
      }

      .text-date {
        @include mixin.media-all-pc {
          font-size: 1.6rem;
        }

        @include mixin.media-all-sp {
          font-size: mixin.vw-value(30);
        }

        min-height: 1.2em;
        text-align: center;
      }

      .text-title {
        @include mixin.media-all-pc {
          margin-top: 0.3rem;
          font-size: 2rem;
        }

        @include mixin.media-all-sp {
          margin-top: mixin.vw-value(8);
          font-size: mixin.vw-value(34);
        }

        margin-right: auto;
        margin-left: auto;
        text-align: center;
        font-weight: 700;
        line-height: 1.5;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
    }

    .Home__article {
      // 改行コードがはいってきたら改行するようにする
      white-space: pre-wrap;
      overflow-wrap: break-word;

      @include mixin.media-all-pc {
        padding-bottom: 2.3rem;
      }

      @include mixin.media-all-sp {
        padding-bottom: mixin.vw-value(56);
      }

      @at-root {
        .Home__article-btn-return {
          @include mixin.media-all-pc {
            left: 0.6rem;
            width: 2.9rem;
            height: 2.7rem;
            background-size: 2.1rem 1.9rem;
          }

          @include mixin.media-all-sp {
            left: 0.2rem;
            width: 2rem;
            height: 1.8rem;
            background-size: 1.6rem 1.4rem;
          }

          position: absolute;
          background-image: url('../../../../public/assets/img/common/icon/arrow_left_02.svg');
          background-repeat: no-repeat;
          background-position: center center;
          cursor: pointer;
        }
      }
    }

    .Home__article-body {
      // 改行コードがはいってきたら改行するようにする
      white-space: pre-wrap;
      overflow-wrap: break-word;

      @include mixin.media-all-pc {
        margin-top: 2.5rem;
      }

      @include mixin.media-all-sp {
        margin-top: mixin.vw-value(34);
      }

      p {
        @include mixin.media-all-pc {
          margin-top: 2.7rem;
          font-size: 1.4rem;
        }

        @include mixin.media-all-sp {
          margin-top: mixin.vw-value(34);
          font-size: mixin.vw-value(26);
        }

        line-height: 1.6;
        text-align: center;
      }
    }

    .Home__article-lead {
      @include mixin.media-all-pc {
        font-size: 1.9rem !important;
      }

      @include mixin.media-all-sp {
        font-size: mixin.vw-value(32) !important;
      }

      margin-top: 0;
      text-align: center;
      font-weight: 700;
      line-height: 1.5;
    }

    .Home__handedOvered {
      padding-bottom: 2.3rem;
      text-align: center;
      color: #ff0000;
      font-size: 1.5rem;
      line-height: 1.5;
      min-height: 1.2em;

      & a {
        color: mixin.$color-blue;
        text-decoration: underline;
        text-decoration-color: mixin.$color-blue;
      }
    }

    .Home__article-note {
      font-size: 1.1rem !important;
      margin-top: 1rem !important;
    }

    .Home__mtg {
      @include mixin.media-all-pc {
        margin-top: 2.5rem;
        padding-bottom: 2rem;
      }

      @include mixin.media-all-sp {
        padding-top: mixin.vw-value(34);
        padding-bottom: mixin.vw-value(56);
      }
    }

    .Home__mtg-desc {
      @include mixin.media-all-sp {
        padding-bottom: mixin.vw-value(33);
        border-bottom: 1px solid mixin.$color-mediumGray;
      }

      display: flex;
      align-items: center;
      position: relative;
    }

    .Home__mtg-desc-list {
      width: 100%;

      li {
        @include mixin.media-all-pc {
          font-size: 1.5rem;
        }

        @include mixin.media-all-sp {
          font-size: mixin.vw-value(26);
        }

        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height: 1.6;

        .Home__mtg-desc-label {
          flex-shrink: 1;
          flex-grow: 0;
          font-weight: 700;
        }

        span {
          flex-grow: 1;
          font-weight: 700;
          word-wrap: break-word;
          overflow-wrap: break-word;
          max-width: 80%;
          display: inline-block;
        }

        ul {
          li {
            font-weight: 700;
          }
        }

        .Home__mtg-desc-link {
          line-height: 2;
          padding-top: 0.5rem;

          .icon-link {
            width: 200px;
          }
        }

        .Home__mtg-desc-url {
          display: block;
          font-weight: 500;
          margin-top: 0;
        }
      }
    }

    .Home__mtg-desc-btns {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;

      span {
        @include mixin.media-all-pc {
          margin-right: 1.6rem;
        }

        & + span {
          @include mixin.media-all-sp {
            margin-left: mixin.vw-value(25);
          }
        }
      }
    }

    .Home__mtg-notice {
      @include mixin.media-all-pc {
        margin-top: 1.4rem;
        padding-top: 1.2rem;
        padding-right: 1.4rem;
        padding-left: 1.4rem;
        padding-bottom: 1.2rem;
        font-size: 1.2rem;
      }

      @include mixin.media-all-sp {
        margin-top: mixin.vw-value(24);
        padding-top: mixin.vw-value(24);
        padding-right: mixin.vw-value(30);
        padding-left: mixin.vw-value(30);
        padding-bottom: mixin.vw-value(24);
        font-size: mixin.vw-value(24);
      }

      background-color: #eeeeee;
      font-weight: 700;
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: pre-line;
    }

    .Home__media {
      @include mixin.media-all-pc {
        padding-top: 2rem;
        padding-bottom: 4.5rem;
      }

      @include mixin.media-all-sp {
        padding-top: mixin.vw-value(42);
        padding-bottom: mixin.vw-value(42);
      }

      border-top: 1px solid mixin.$color-mediumGray;

      .Home__media-list {
        display: flex;
        flex-wrap: wrap;
      }

      &.-icon-document {
        .Home__media-list {
          @include mixin.media-all-sp {
            margin-top: 1rem;
          }

          li {
            @include mixin.media-all-pc {
              width: 14rem;
              height: 12.5rem;
              margin-top: 2rem;
              margin-right: 2rem;
            }

            @include mixin.media-all-sp {
              width: mixin.vw-value(328);
              height: mixin.vw-value(290);
              margin-top: 1rem;
              margin-right: mixin.vw-value(19);
            }

            position: relative;
            border: 1px solid #c1c1c1;
            border-radius: 5px;
            overflow: hidden;

            &.-flag-bg {
              &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.8);
                z-index: 30;
                pointer-events: none;
              }
            }

            &.-flag-single::before {
              @include mixin.media-all-pc {
                background-size: 3rem;
              }

              @include mixin.media-all-sp {
                background-size: 4.4rem;
              }
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-image: url('../../../../public/assets/img/common/icon/flag_on.svg');
              background-repeat: no-repeat;
              background-position: center center;
              background-color: rgba(255, 255, 255, 0.8);
              z-index: 30;
              pointer-events: none;
            }

            &:nth-child(2n) {
              @include mixin.media-all-sp {
                margin-right: 0;
              }
            }
            &:nth-child(4n) {
              @include mixin.media-all-pc {
                margin-right: 0;
              }
            }

            .check-flag {
              position: absolute;
              width: 2rem;
              top: 50%;
              transform: translateY(-70%);
              pointer-events: none;
              z-index: 50;
              &:nth-of-type(1) {
                @include mixin.media-all-pc {
                  left: 0.9rem;
                }
                @include mixin.media-all-sp {
                  left: 2.6vw;
                }
              }
              &:nth-of-type(2) {
                @include mixin.media-all-pc {
                  left: 3.4rem;
                }
                @include mixin.media-all-sp {
                  left: 11vw;
                }
              }
              &:nth-of-type(3) {
                @include mixin.media-all-pc {
                  left: 5.9rem;
                }
                @include mixin.media-all-sp {
                  left: 19vw;
                }
              }
              &:nth-of-type(4) {
                @include mixin.media-all-pc {
                  left: 8.4rem;
                }
                @include mixin.media-all-sp {
                  left: 27vw;
                }
              }
              &:nth-of-type(5) {
                @include mixin.media-all-pc {
                  left: 10.9rem;
                }
                @include mixin.media-all-sp {
                  left: 35vw;
                }
              }
            }

            a {
              display: block;
            }

            .text-name {
              @include mixin.media-all-pc {
                padding-top: 0.4rem;
                padding-bottom: 0.3rem;
                font-size: 1rem;
              }
              @include mixin.media-all-sp {
                padding-top: 0.5rem;
                padding-bottom: 0.4rem;
                font-size: mixin.vw-value(24);
              }

              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              padding-right: 0.6em;
              padding-left: 0.6em;
              background-color: rgba(255, 255, 255, 0.8);
              text-align: center;
              font-weight: 700;
              line-height: 1.4;
              letter-spacing: -0.01em;
              word-break: break-all;
            }

            .text-number {
              @include mixin.media-all-pc {
                top: -3rem;
                font-size: 2rem;
              }
              @include mixin.media-all-sp {
                top: -3.6rem;
                font-size: mixin.vw-value(50);
              }

              position: absolute;
              right: 0.8rem;
              color: #fff;
              text-align: center;
              z-index: 50;
              filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.5));
            }
          }
        }
      }

      &.-icon-file {
        .Home__media-list {
          @include mixin.media-all-sp {
            margin-top: 1rem;
          }

          li {
            @include mixin.media-all-pc {
              width: 10rem;
              margin-top: 2rem;
              margin-right: 3rem;
            }

            @include mixin.media-all-sp {
              width: mixin.vw-value(200);
              margin-top: 1rem;
              margin-right: mixin.vw-value(37);
            }

            &:nth-child(3n) {
              @include mixin.media-all-sp {
                margin-right: 0;
              }
            }
            &:nth-child(5n) {
              @include mixin.media-all-pc {
                margin-right: 0;
              }
            }

            a {
              display: block;
            }

            img {
              @include mixin.media-all-pc {
                width: 10rem;
                height: 10rem;
              }

              @include mixin.media-all-sp {
                width: mixin.vw-value(200);
                height: mixin.vw-value(200);
              }

              border: 1px solid #c1c1c1;
              object-fit: cover;
            }

            .text-name {
              @include mixin.media-all-pc {
                margin-top: 0.1rem;
                padding-top: 0.4rem;
                padding-bottom: 0.3rem;
                font-size: 1rem;
              }
              @include mixin.media-all-sp {
                padding-top: 0.5rem;
                padding-bottom: 0.4rem;
                font-size: mixin.vw-value(24);
              }
              display: block;
              width: 100%;
              text-align: center;
              font-weight: 700;
              line-height: 1.4;
              letter-spacing: -0.01em;
              word-break: break-all;
            }
          }
        }
      }
    }

    .Home__flex-box {
      display: flex;
      justify-content: flex-start !important;
      align-items: flex-start !important;
    }
  }
}
