#column_article.article_31 {
  .article__column-title h2 span {
    bottom: 5px;
    left: 70px;
    font-size: 19px;
    color: #000;
    margin-left: 20px;
  }

  .article__column-title h2 {
    padding: 0 0 20px 70px;
  }

  .article__font-color-1 {
    color: rgb(178, 120, 180);
  }

  .article__font-color-2 {
    color: rgb(127, 108, 99);
  }

  .article__font-color-3 {
    color: rgb(68, 59, 55);
  }

  .article__font-color-4 {
    color: rgb(164, 190, 95);
  }

  @media (max-width: 768px) {
    .article__column-title h2 {
      padding: 0 0 20px 45px;
    }

    .article__column-title h2 span {
      left: 45px;
      font-size: 9px;
    }

    a {
      font-size: 14px;
    }
  }
}
