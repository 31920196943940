#column_article.article_10 {
  .article__image-two-col-side.article__image-1
    .article__image-two-col-item:nth-of-type(1) {
    width: 60%;
  }

  .article__image-two-col-side.article__image-1
    .article__image-two-col-item:nth-of-type(2) {
    width: 34%;
  }

  .article__image-two-col-side.article__image-2
    .article__image-two-col-item:nth-of-type(1) {
    width: 33%;
  }

  .article__image-two-col-side.article__image-2
    .article__image-two-col-item:nth-of-type(2) {
    width: 61%;
  }

  .article__image-two-col-side.article__image-3 {
    justify-content: left;
  }

  .article__image-two-col-side.article__image-3
    .article__image-two-col-item:nth-of-type(1) {
    width: 30%;
    padding: 0 15px 0 0;
  }

  .article__image-two-col-side.article__image-3
    .article__image-two-col-item:nth-of-type(2) {
    width: 30%;
    padding: 0 0 0 15px;
  }

  .article__image-two-col-side.article__image-4
    .article__image-two-col-item:nth-of-type(1) {
    width: 36%;
    padding: 0 15px 0 0;
  }

  .article__image-two-col-side.article__image-4
    .article__image-two-col-item:nth-of-type(2) {
    width: 58%;
    padding: 0 0 0 15px;
  }

  .article__image-two-col-side.article__image-4
    .article__image-two-col-item
    .article__image-two-col-item:nth-of-type(1) {
    width: 50%;
    padding: 0 15px 0 0;
  }

  .article__image-two-col-side.article__image-4
    .article__image-two-col-item
    .article__image-two-col-item:nth-of-type(2) {
    width: 56%;
    padding: 0 0 0 15px;
  }

  .article__image-two-col-side.article__image-5
    .article__image-two-col-item:nth-of-type(1) {
    width: 48%;
    padding: 0 15px 0 0;
  }

  .article__image-two-col-side.article__image-5
    .article__image-two-col-item:nth-of-type(2) {
    width: 46%;
    padding: 0 0 0 15px;
  }

  .article__column-content .article__image-unique h3 {
    padding: 0 0px 0 40px;
    margin-top: 0;
  }

  .article__column-content h3.mt-0 {
    margin-top: 0;
  }

  @media (max-width: 768px) {
    .article__image-two-col-side .article__image-two-col-side {
      display: block;
    }

    .article__image-two-col-side.article__image-4
      .article__image-two-col-item:nth-of-type(1) {
      width: 60%;
      padding: 0;
    }

    .article__image-two-col-side.article__image-4
      .article__image-two-col-item:nth-of-type(2) {
      width: 33%;
      padding: 0;
    }

    .article__image-two-col-side.article__image-4
      .article__image-two-col-item
      .article__image-two-col-item:nth-of-type(1) {
      width: 100%;
      padding: 0;
    }

    .article__image-two-col-side.article__image-4
      .article__image-two-col-item
      .article__image-two-col-item:nth-of-type(2) {
      width: 100%;
      padding: 0;
    }

    .article__column-content .article__image-unique h3 {
      padding: 0 0px 0 40px;
      margin-top: 1rem;
    }

    .article__column-content h3.mt-0 {
      margin-top: 1rem;
    }

    .article__image-two-col-side {
      margin: 0px auto 20px;
    }
  }
}
