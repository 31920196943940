// ==================================================
// Components
// ==================================================

// scaffolding
//---------------------------------------

html {
  font-size: 62.5%; // 10px
  overflow: auto;
  overscroll-behavior: none;

  & * {
    box-sizing: border-box;
  }
}

body {
  height: 100%;
  font-family:
    Meiryo,
    'メイリオ',
    筑紫ゴシックpro-M,
    Hiragino Sans,
    'ヒラギノ角ゴシック',
    Hiragino Kaku Gothic ProN,
    'ヒラギノ角ゴ ProN W3',
    Roboto,
    'Droid Sans',
    YuGothic,
    '游ゴシック',
    Verdana,
    'ＭＳ Ｐゴシック',
    sans-serif;
  background-color: #fff;
  color: #333;
  line-height: 1.2;
  letter-spacing: 0.08em;
  -webkit-print-color-adjust: exact;
}

img {
  width: 100%;
  vertical-align: bottom;
}

button {
  padding: 0;
  border: 0;
  border-radius: 0;
  background: none transparent;
  color: inherit;
  font-size: inherit;
  line-height: 1;
  outline: none;
  appearance: none;
  cursor: pointer;
}

a {
  color: inherit;
  transition: opacity 0.5s;
  text-decoration: none;
  cursor: pointer;
}

sup {
  font-size: 60%;
  vertical-align: top;
  position: relative;
  top: -0.1em;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
}

input {
  font-family:
    Meiryo,
    'メイリオ',
    筑紫ゴシックpro-M,
    Hiragino Sans,
    'ヒラギノ角ゴシック',
    Hiragino Kaku Gothic ProN,
    'ヒラギノ角ゴ ProN W3',
    Roboto,
    'Droid Sans',
    YuGothic,
    '游ゴシック',
    Verdana,
    'ＭＳ Ｐゴシック',
    sans-serif;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }
}

textarea {
  font-family:
    Meiryo,
    'メイリオ',
    筑紫ゴシックpro-M,
    Hiragino Sans,
    'ヒラギノ角ゴシック',
    Hiragino Kaku Gothic ProN,
    'ヒラギノ角ゴ ProN W3',
    Roboto,
    'Droid Sans',
    YuGothic,
    '游ゴシック',
    Verdana,
    'ＭＳ Ｐゴシック',
    sans-serif;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }
}
