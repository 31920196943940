@use '../../../sass/foundation/mixin';

// ==================================================
// Utility
// ==================================================

// display
// ==========================================================================

.u-display-pc {
  @include mixin.media-all-sp {
    display: none;
  }
}

.u-display-sp {
  @include mixin.media-all-pc {
    display: none;
  }
}

.u-print-hidden {
  @media print {
    display: none !important;
  }
}
