@use '../../sass/foundation/mixin';

// ==================================================
// Components
// ==================================================

// Header
//---------------------------------------

.Header {
  @at-root {
    .Header__root-sp {
      @include mixin.media-all-pc {
        display: none;
      }

      @include mixin.media-all-sp {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 4.4rem;
        border-top: 1px solid mixin.$color-mediumGray;
        border-bottom: 1px solid mixin.$color-mediumGray;
        background-color: #fff;
        z-index: 1000;
        transition: left 0.4s ease;
      }
    }

    .Header__title {
      font-size: 1.4rem;
      font-weight: 700;

      > span {
        display: block;
        width: 13.6rem;
      }
    }

    .Header__btn-sp {
      position: absolute;
      left: 1.8rem;
      padding: 0.3rem;

      span {
        display: block;
        width: 1.6rem;
        height: 0.2rem;
        background-color: #7b7b7b;

        &.bar02 {
          margin-top: 0.4rem;
        }

        &.bar03 {
          margin-top: 0.4rem;
        }
      }
    }

    .Header__home {
      position: absolute;
      right: 1.5rem;
      width: 3.2rem;
      height: 3.2rem;
      padding-top: 0.5rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
}
