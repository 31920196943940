#column_article.article_28 {
  .article__font-sml.article__column-content p {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    a {
      font-size: 14px;
    }

    .article__font-sml.article__column-content p {
      font-size: 9px;
    }

    .article__column-content
      .article__image-three-col
      h3.article__check::before {
      width: 20px;
      left: 0px;
      top: -2px;
    }

    .article__column-content .article__image-three-col h3 {
      padding: 0 0 0 25px;
      font-size: 14px;
    }
  }
}
