#column_article.article_45 {
  .article__column-content {
    clear: both;
    content: '';
    position: relative;
  }

  .article__clear-both {
    clear: both;
  }

  .article__45-page1-section1-item {
    float: left;
    width: 50%;
    padding: 2px;
  }

  .article__45-page1-section2-item-center {
    float: left;
    width: 100%;
    text-align: center;
  }

  .article__45-page1-section2-item-center img {
    width: 50%;
    text-align: center;
  }

  .article__45-page1-section2-box1 {
    margin-top: 50px;
    position: relative;
  }

  .article__45-page1-section2-box1-image-1,
  .article__45-page1-section2-box1-image-2 {
    position: absolute;
    width: 70px;
    top: -40px;
  }

  .article__45-page1-section2-box1-image-1 {
    left: 9%;
  }

  .article__45-page1-section2-box1-image-2 {
    left: 43%;
  }

  .article__45-page1-section2-box2 {
    margin-top: 20px;
  }

  .article__45-page1-section2-box2::after {
    clear: both;
  }

  .article__45-page1-section2-box2-item1 {
    width: 60%;
    height: 100%;
    float: left;
  }

  .article__45-page1-section2-box2-item2 {
    width: 30%;
    margin: 3%;
    float: right;
    text-align: center;
  }

  .article__image_tv_lan {
    width: 206px;
  }

  @media (max-width: 768px) {
    .article__image_tv_lan {
      width: 67%;
      text-align: center;
    }

    .article__45-page1-section2-item-center img {
      width: 75%;
      text-align: center;
    }

    .article__45-page1-section2-box1-image-1,
    .article__45-page1-section2-box1-image-2 {
      position: absolute;
      width: 53px;
      top: -40px;
    }
  }

  .article__layout {
    width: 50%;
    margin: 0 auto;
    text-align: center;
  }

  .article__layout img:first-of-type {
    width: 70%;
  }
}
