#column_article.article_14 {
  .article__column-content h3 {
    padding: 0 0 0 40px;
  }

  .ta-center {
    text-align: center;
  }

  .po-relative {
    position: relative;
  }

  .po-abso {
    position: absolute;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mt-0 {
    margin-top: 0;
  }

  .font-red {
    color: red;
  }

  .article__image-two-col-side .article__image-two-col-item.w-60 {
    width: 58%;
  }

  .article__image-two-col-side .article__image-two-col-item.w-40 {
    width: 37%;
  }

  .vertical,
  .horizon {
    background-color: #fff;
    font-size: 13px;
    width: 65px;
    text-align: center;
  }

  .horizon {
    top: 73%;
    left: 12px;
  }

  .vertical {
    bottom: -10px;
    left: 35%;
  }

  .article__column-content p.article__sml {
    font-size: 19px;
  }

  @media (max-width: 640px) {
    .vertical,
    .horizon {
      font-size: 0.5rem;
      width: 43px;
    }

    .horizon {
      left: 6px;
    }

    .original-wrap {
      flex-wrap: wrap;
    }

    .article__image-two-col-side.original-wrap
      .article__image-two-col-item.w-60,
    .article__image-two-col-side.original-wrap
      .article__image-two-col-item.w-40 {
      width: 100%;
    }
  }

  .article__column-content h3.mt-0 {
    margin-top: 0;
  }

  .article__image-two-col-side.article__image-two-jc-sa {
    justify-content: space-around;
  }

  @media (max-width: 768px) {
    .article__column-content h3.mt-0 {
      margin-top: 20px;
    }

    .article__column-content p.article__sml {
      font-size: 13px;
      line-height: 20px;
    }

    .article__image-two-col-side.article__image-size-ori
      .article__image-two-col-item:nth-of-type(1) {
      width: 60%;
    }

    .article__image-two-col-side.article__image-size-ori
      .article__image-two-col-item:nth-of-type(2) {
      width: 40%;
    }

    p.mt-20 {
      margin: 10px 0;
      margin-top: 20px;
    }
  }
}
