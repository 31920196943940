#column_article.article_60 {
  .line {
    background: rgba(0, 0, 0, 0) linear-gradient(transparent 55%, #ffff6c 0%)
      repeat scroll 0 0;
  }

  .sub_txt {
    text-align: right;
    font-size: 18px !important;
    line-height: 1.4em !important;
  }

  .square {
    font-size: 16px !important;
    padding: 4px 6px;
    border: 1px solid #929292;
    float: right;
    vertical-align: 40%;
  }

  .caution {
    font-size: 16px !important;
  }

  .article__image-container_60 {
    margin: 30px auto 10px;
    text-align: center;
  }

  @media (max-width: 768px) {
    p.sp_mb20 {
      margin-bottom: 20px;
    }

    .sub_txt {
      font-size: 13px !important;
      margin-bottom: 20px;
    }

    .square {
      font-size: 13px !important;
    }

    .caution {
      font-size: 13px !important;
    }

    .article__image-container_60 {
      margin: 20px auto 10px;
    }
  }
}
