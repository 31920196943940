#column_article.article_05 {
  .article__content-two-col .article__content-two-col-item {
    width: 68%;
  }

  .article__content-two-col .article__content-two-col-item.article__right-img {
    width: 32%;
  }

  .article__column-content h3 {
    margin: 0 0 1rem;
  }

  .article__column-content p.ma-0.pl-40 {
    margin: 0;
    padding-left: 40px;
  }

  @media (max-width: 768px) {
    .article__column-content p {
      margin-bottom: 15px;
    }

    .article__content-two-col {
      justify-content: left;
    }

    .article__content-two-col .article__content-two-col-item {
      width: 100%;
    }

    .article__content-two-col
      .article__content-two-col-item.article__right-img {
      width: 100%;
    }

    .article__column-content h3 {
      margin: 1rem 0;
    }
  }
}
