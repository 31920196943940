@import '../../../sass/foundation/mixin';

#FloatingServerLabel {
  @media all and (min-width: 769px) {
    bottom: 1.8rem;
    left: 28rem;
  }

  @media all and (max-width: 768px) {
    bottom: 6.8rem;
    left: 2rem;
  }

  position: fixed;
  bottom: 6.8rem;
  padding-top: 1rem;
  padding-right: 5rem;
  padding-left: 5rem;
  padding-bottom: 1rem;
  border: 2px solid $color-red;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 100000;
  pointer-events: none;

  p {
    font-size: 1.6rem;
    color: $color-red;
  }
}
