#column_article.article_11 {
  .article__image-container img {
    width: 100%;
  }

  .article__column-title p {
    font-size: 19px;
    line-height: 30px;
  }

  @media (max-width: 768px) {
    .article__image-two-col-side {
      flex-wrap: wrap;
    }

    .article__column-title h2 {
      padding: 0 0px 5px 45px;
    }

    .article__column-title p {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
