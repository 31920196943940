#column_article.article_24 {
  .article__image-three-col {
    flex-wrap: wrap;
  }

  .article__column-content p.article__figurement.row-2 span {
    word-break: break-all;
  }

  .article__column-content h3 {
    position: relative;
    padding: 0 0 0 40px;
  }

  .mt-2-pc {
    margin-top: 2rem;
  }

  .article__content-two-col .article__content-two-col-item.article__right-img {
    margin-bottom: 1rem;
  }

  .article__column-content p.article__font-small {
    font-size: 21px;
  }

  .article__column-content p.article__font-small-2 {
    font-size: 18px;
  }

  .article__column-content p.article__sml-title {
    font-size: 18px;
  }

  .article__column-content p.article__sml-thumb {
    font-size: 14px;
    line-height: 25px;
  }

  .article__image-container {
    text-align: left;
  }

  .article__column-content p {
    font-size: 19px;
    line-height: 40px;
  }

  .article__layout {
    width: 100%;
    padding: 0 15px;
  }

  @media (max-width: 768px) {
    .mt-2-pc {
      margin-top: 0;
    }

    .article__column-content p {
      margin: 10px 0;
    }

    .article__column-content p.article__font-small {
      font-size: 10px;
    }

    .article__column-content p.article__font-small-2 {
      font-size: 0.5rem;
    }

    .article__column-content p.article__sml-title {
      min-height: 20px;
    }

    p.article__figurement.article__sml-title span {
      /*    font-size: 8px;*/
      font-size: 12px;
    }

    .article__column-content p.article__sml-thumb {
      font-size: 6px;
      line-height: 10px;
      margin: 0;
    }

    .article__column-content p {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
