#column_article.article_19 {
  .article__content-two-col.exterior
    .article__content-two-col-item.article__title-img {
    width: 27%;
  }

  .article__content-two-col.exterior .article__content-two-col-item {
    width: 72%;
  }

  .article__content-two-col.exterior
    .article__content-two-col-item.article__ori-size-1-1 {
    width: 56%;
    padding: 0;
  }

  .article__content-two-col.exterior
    .article__content-two-col-item.article__ori-size-1-2 {
    width: 44%;
    padding: 0;
  }

  .article__content-two-col.title-area {
    justify-content: left;
  }

  .article__content-two-col.title-area .article__content-two-col-item.title {
    width: 100%;
  }

  .article__column-content h3 {
    position: relative;
    padding: 0 0px 0 40px;
  }

  .article__content-two-col.title-area .article__content-two-col-item {
    width: 100%;
  }

  .article__text-container {
    margin: 1rem 0;
  }

  @media (max-width: 768px) {
    .article__content-two-col.exterior
      .article__content-two-col-item.article__title-img {
      width: 29%;
      margin: 0 0 0.2rem;
    }

    .article__content-two-col.exterior .article__content-two-col-item {
      width: 71%;
      margin: 0 0 0.2rem;
    }

    .article__content-two-col.title-area .article__content-two-col-item.title,
    .article__content-two-col.title-area .article__content-two-col-item {
      width: 100%;
    }

    .article__column-content p {
      margin: 0 0 10px;
    }

    .article__link-font {
      font-size: 14px;
    }

    .article__content-two-col.exterior {
      margin: 2rem 0 0;
    }

    .article__figure.article__figure__19 {
      font-size: 15px;
    }
  }
}
