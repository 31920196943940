#column_article.article_06 {
  .article__content-two-col.article__reverse-cal {
    flex-direction: row-reverse;
  }

  .article__content-two-col .article__content-two-col-item {
    width: 68%;
  }

  .article__content-two-col .article__content-two-col-item.article__left-img {
    width: 32%;
    padding: 0 20px 0 0;
    box-sizing: border-box;
  }

  .article__image-container.article__image-some-col-item.article__image-some-col-flex
    img {
    height: 100%;
  }

  .article__image-three-col-side
    .article__image-three-col-item
    .po-relative::after {
    position: absolute;
    content: '';
    background-image: url('../../../../../public/assets/img/appointments/agendas/articles/img/06/06_image-ekijouka1-4.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 26px;
    height: 35px;
    top: 50%;
    right: -26%;
    transform: translate(-50%, 0);
  }

  .po-relative {
    position: relative;
  }

  .article__column-content .article__image-three-col-side p {
    font-size: 18px;
    line-height: 30px;
  }

  .article__content-two-col.po-relative .article__content-two-col-item {
    width: 47%;
  }

  .article__content-two-col.po-relative
    .article__content-two-col-item.article__left-img {
    width: 47%;
    padding: 0;
    box-sizing: border-box;
  }

  .article__content-two-col.po-relative::after {
    position: absolute;
    content: '';
    background-image: url('../../../../../public/assets/img/appointments/agendas/articles/img/06/06_image-ekijouka1-4.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 26px;
    height: 35px;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%, 0) rotate(90deg);
  }

  .article__content-two-col.po-relative:last-of-type::after {
    content: none;
  }

  .article__content-two-col.po-relative {
    margin: 50px 0;
  }

  @media (max-width: 768px) {
    .article__image-some-col-item {
      margin: 1rem 0;
    }

    .article__column-content h3 {
      margin: 1rem 0;
    }

    .article__content-two-col.article__text-only {
      padding: 1rem 0 0;
    }

    .article__content-two-col .article__content-two-col-item {
      width: 100%;
    }

    .article__content-two-col .article__content-two-col-item.article__left-img {
      width: 100%;
      padding: 0;
      box-sizing: border-box;
    }

    .article__image-three-col-side {
      margin: 20px auto;
    }

    .article__column-content p {
      margin: 0 0 15px;
    }
  }
}
