@use '../../../sass/foundation/mixin';

// ==================================================
// Project
// ==================================================

// NotificationSetting
// ==========================================================================

.NotificationSetting {
  @include mixin.media-all-pc {
    padding-top: 2.8rem;
  }
  @include mixin.media-all-sp {
    padding-top: mixin.vw-value(26);
    overflow-y: scroll;
  }

  height: 100%;
  background-color: #fbfbfb;

  .NotificationSetting__inner {
    @include mixin.media-all-pc {
      margin-top: 2.5rem;
    }

    @include mixin.media-all-sp {
      margin-top: mixin.vw-value(24);
    }

    border-top: 1px solid mixin.$color-mediumGray;
    border-bottom: 1px solid mixin.$color-mediumGray;
    background-color: #fff;
  }

  @at-root {
    .NotificationSetting__lead {
      @include mixin.media-all-pc {
        font-size: 2rem;
      }

      @include mixin.media-all-sp {
        font-size: mixin.vw-value(24);
      }

      font-weight: 700;
      text-align: center;
    }

    .NotificationSetting__description {
      @include mixin.media-all-pc {
        margin-top: 1.3rem;
        font-size: 1.5rem;
      }

      @include mixin.media-all-sp {
        margin-top: mixin.vw-value(22);
        font-size: mixin.vw-value(22);
        line-height: 1.6;
      }

      color: #707070;
      text-align: center;
    }

    .Setting__mail-adresss-filter {
      @include mixin.media-all-pc {
        width: 72.6rem;
        margin-top: 2.2rem;
      }

      @include mixin.media-all-sp {
        width: 93vw;
        margin-top: 4.1vw;
      }

      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: auto;
      margin-left: auto;

      label {
        @include mixin.media-all-pc {
          margin-right: 2rem;
          font-size: 1.5rem;
        }

        @include mixin.media-all-sp {
          margin-right: mixin.vw-value(20);
          font-size: mixin.vw-value(23);
        }

        flex-shrink: 0;
      }

      .Setting__select-wrap {
        @include mixin.media-all-sp {
          flex-shrink: 2;
          width: mixin.vw-value(510);
        }

        &::after {
          @include mixin.media-all-pc {
            top: 1.3rem;
            right: 3.7rem;
            border-top: 11px solid #777;
            border-right: 11px solid rgba(0, 0, 0, 0);
            border-left: 11px solid rgba(0, 0, 0, 0);
          }

          @include mixin.media-all-sp {
            top: mixin.vw-value(22);
            right: mixin.vw-value(56);
            border-top: 9px solid #777;
            border-right: 9px solid rgba(0, 0, 0, 0);
            border-left: 9px solid rgba(0, 0, 0, 0);
          }

          content: '';
          position: relative;
          pointer-events: none;
        }
      }

      select {
        @include mixin.media-all-pc {
          width: 32.4rem;
          padding-top: 1.1rem;
          padding-right: 1.4rem;
          padding-left: 1.6rem;
          padding-bottom: 1.1rem;
        }

        @include mixin.media-all-sp {
          width: 100%;
          padding-top: mixin.vw-value(15);
          padding-right: mixin.vw-value(20);
          padding-left: mixin.vw-value(20);
          padding-bottom: mixin.vw-value(15);
        }

        border: 1px solid #b7b7b7;
        border-radius: 6px;
      }
      option {
        font-size: 1.5rem;
      }
    }

    .Setting__list {
      @include mixin.media-all-pc {
        width: 72.6rem;
      }

      @include mixin.media-all-sp {
        width: 93vw;
      }

      margin-right: auto;
      margin-left: auto;

      li {
        @include mixin.media-all-pc {
          padding-top: 2.1rem;
          padding-bottom: 2.2rem;
        }

        @include mixin.media-all-sp {
          padding-top: mixin.vw-value(40);
          padding-bottom: mixin.vw-value(40);
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;

        & + li {
          padding-top: 3rem;
          border-top: 1px solid mixin.$color-mediumGray;
        }
      }
    }

    .Setting__right {
      @include mixin.media-all-pc {
        padding-left: 3.9rem;
      }

      @include mixin.media-all-sp {
        padding-left: 1.6rem;
      }
    }

    .Setting__label {
      @include mixin.media-all-pc {
        font-size: 2.8rem;
      }

      @include mixin.media-all-sp {
        font-size: mixin.vw-value(30);
        font-weight: 600;
      }

      display: block;
    }

    .Setting__description {
      @include mixin.media-all-pc {
        font-size: 1.5rem;
      }

      @include mixin.media-all-sp {
        font-size: 1.3rem;
      }

      display: block;
      margin-top: 0.5rem;
      line-height: 1.6;
      letter-spacing: 0.06em;
    }
  }
}
