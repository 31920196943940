@import '../../../../sass/foundation/mixin';

.AddTaskForm {
  display: flex;
  flex-direction: column;
  border: 1.5px solid #d6d6d6;
  border-radius: 1.3rem;
  margin: 16px;

  &__input {
    display: flex;
    flex-direction: row;
    padding: 0.8rem;
    border-bottom: 1px solid #d6d6d6;

    label {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      // cursor: pointer; // colorを変更する場合
      transform: scale(0.9);

      span {
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 0.5rem;
          width: 2.6rem;
          height: 2.6rem;
          border: 1.5px solid #d6d6d6;
          border-radius: 1.3rem;
          background-color: #fff;
          transform: translateY(-50%);
          box-sizing: border-box;
        }
      }
    }

    input {
      min-height: 2rem;
      padding-top: 0.8rem;
      padding-right: 0.8rem;
      padding-left: 4rem;
      padding-bottom: 0.8rem;
      font-size: 1.3rem;
      // iOSでは文字が小さいときにズームしてしまうため下記で対応
      @include media-screen-sp {
        font-size: 16px;
      }
      border: none;
      width: 100%;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0.8rem;
    background-color: $color-lightBlue;
    border-radius: 0 0 1.2rem 1.2rem;
  }

  &__error {
    margin: 1rem;
    color: red;
  }

  &__cancel {
    span {
      margin-right: 1rem;
      color: gray;
      cursor: pointer;
    }
  }
}
