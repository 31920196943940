#column_article.article_59 {
  .article__content-two-col
    .article__content-two-col-item.article__layout:nth-of-type(2) {
    width: 75%;
  }

  .article__content-two-col
    .article__content-two-col-item.article__layout:nth-of-type(1) {
    width: 20%;
  }

  .article__column-content p {
    font-size: 17px;
    line-height: 25px;
  }

  .article__content-two-col .article__content-two-col-item.article__right-img {
    margin: 1rem 0;
  }

  .article__column-content p.article__thumb-row2 {
    min-height: 49.33px;
    display: flex;
    align-items: flex-end;
  }

  .article__sp {
    display: none;
  }

  @media (max-width: 768px) {
    .article__content-two-col
      .article__content-two-col-item.article__layout:nth-of-type(2) {
      width: 65%;
    }

    .article__content-two-col
      .article__content-two-col-item.article__layout:nth-of-type(1) {
      width: 30%;
    }

    .article__column-content p {
      font-size: 12px;
      line-height: 17px;
    }

    .article__column-content .article__font-small p {
      font-size: 8px;
      line-height: 14px;
    }

    .article__column-content .article__font-small p.article__thumb-row2 {
      min-height: 28px;
    }

    .article__image-three-col {
      margin: 1rem 0;
    }

    .article__column-content p.article__font-bg {
      font-size: 14px;
      line-height: 22px;
    }

    .article__sp {
      display: block;
    }

    .article__column-content .article__font-small p {
      font-size: 12px;
    }

    .article__font-vr .article__image-three-col-item p {
      min-height: 28px;
      display: flex;
      align-items: flex-end;
    }
  }
}
