#column_article.article_62 {
  .article__image-container {
    margin: 10px auto 0px;
  }

  @media (max-width: 768px) {
    .article__image-container img {
      max-width: 100%;
    }

    .article__image-container {
      margin: 10px auto 0px;
    }

    .article__pb-2-sp {
      padding-bottom: 20px;
    }
  }

  .po-relative {
    position: relative;
    margin-bottom: 40px;
  }

  .po-relative::after {
    position: absolute;
    content: '';
    background-image: url('../../../../../public/assets/img/appointments/agendas/articles/img/62/62_image-ekijouka1-4.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 26px;
    height: 35px;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%, 0) rotate(90deg);
  }
}
