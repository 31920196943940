#column_article.article_13 {
  .article__column-content h3 {
    padding: 0 0 0 40px;
    font-size: 24px;
  }

  .article__image-two-col-side.article__image-5.second {
    margin: 0;
  }

  .article__image-two-col,
  .article__image-three-col,
  .article__image-four-col {
    align-items: center;
  }

  .position_relative {
    position: relative;
  }

  .position_change {
    position: absolute;
    bottom: -11px;
    left: 164px;
    margin: 0;
  }

  .article__image-three-col-side {
    border: 1px solid #000;
    padding: 2px 0;
    margin-bottom: 20px;
    display: block;
  }

  .article__image-four-col.position_relative {
    margin: 20px auto;
  }

  .article__image-three-col-item:nth-of-type(1),
  .article__image-three-col-item:nth-of-type(2) {
    width: 25%;
  }

  .article__image-three-col-item:nth-of-type(3) {
    width: 35%;
  }

  h3.article__text-only {
    margin-top: 0;
  }

  .article__column-content p {
    font-size: 21px;
    line-height: 35px;
  }

  .article__image-two-col-side,
  .article__image-three-col-side {
    flex-wrap: wrap;
  }

  .article__column-content p.article__sml {
    font-size: 18px;
    line-height: 25px;
  }

  .article__image-flex .article__image-two-col-item {
    display: flex;
    flex-wrap: wrap;
  }

  .article__image-flex .article__image-two-col-item span.span_left,
  .article__image-flex .article__image-two-col-item span.span_right {
    display: block;
    letter-spacing: -1.5px;
    font-size: 18px;
    margin-bottom: 8px;
  }

  .article__image-flex .article__image-two-col-item span.span_right {
    padding-top: 20px;
  }

  .article__image-flex img {
    align-self: flex-end;
  }

  @media (min-width: 769px) {
    .article__image-two-col-side + .article__image-two-col-side img {
      margin-top: 3px;
    }
  }

  @media (max-width: 768px) {
    .article__column-content h3 {
      padding: 0 0 0 35px;
      font-size: 13px;
    }

    .article__image-two-col-side.second .article__image-two-col-item {
      width: 100%;
    }

    .article__image-two-col-side {
      flex-wrap: wrap;
    }

    .article__image-two-col-side.cal-4 .article__image-two-col-item {
      width: 100%;
    }

    .position_change {
      position: relative;
      left: 0;
    }

    .article__image-four-col {
      justify-content: space-between;
    }

    h3.row-4 {
      height: 15px;
    }

    .article__image-two-col-side.article__image-5 {
      margin-bottom: 20px;
    }

    .article__column-content p {
      font-size: 13px;
      line-height: 20px;
    }

    .article__column-content .article__image-two-col-item h3 {
      font-size: 11px;
    }

    .article__column-content p.article__sml {
      font-size: 13px;
      line-height: 20px;
    }

    h3.row-2 {
      height: 21px;
    }

    .article__image-flex .article__image-two-col-item span.span_left,
    .article__image-flex .article__image-two-col-item span.span_right {
      font-size: 11px;
      line-height: 1.2em;
    }
  }
}
