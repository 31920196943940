#column_article.article_68 {
  .article__mb-0 {
    margin-bottom: 0;
  }

  .article__column-title p.start_txt {
    margin: 30px 0 20px;
  }

  .article__image-width-original
    .article__image-some-col-item
    img:nth-of-type(1) {
    width: 60%;
    padding-right: 10px;
    box-sizing: border-box;
  }

  .article__image-width-original
    .article__image-some-col-item
    img:nth-of-type(2) {
    width: 40%;
    padding-left: 10px;
    box-sizing: border-box;
  }

  p.article__text-align-left {
    text-align: left;
  }

  .article__column-content h3 .case {
    font-size: 18px;
  }

  .article__content-two-col {
    margin-top: 20px;
  }

  br.br_sp {
    display: none;
  }

  .ls-8 {
    letter-spacing: -0.8px !important;
  }

  @media (max-width: 768px) {
    .section-last {
      font-size: 13px !important;
    }

    .article__mb-0 {
      margin-bottom: 20px;
    }

    .article__image-some-col-item.article__image-some-col-flex img {
      width: auto;
    }

    p.sp_mb20 {
      margin-bottom: 20px;
    }

    .article__column-content h3 .case {
      font-size: 13px;
    }

    br.br_sp {
      display: block;
    }
  }
}
