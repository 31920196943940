#column_article.article_33 {
  .article__column-content h3 {
    padding: 0 0 0 40px;
    line-height: 26px;
  }

  .article__column-title p {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    .article__column-title p {
      font-size: 14px;
    }

    .article__column-content h3 {
      padding: 0 0 0 35px;
      font-size: 14px;
      line-height: 14px;
      text-align: left;
    }

    a {
      font-size: 14px;
    }

    .article__image-three-col.article__sp-three-col
      .article__image-three-col-item {
      width: 30%;
    }
  }
}
