@use '../../../sass/foundation/mixin';
// ==================================================
// Component
// ==================================================

// Announce
// ==========================================================================

.Announce__note {
  &.message {
    position: relative;
    z-index: 0;
  }
  width: 100%;
  background-color: #ffe5e5;
  z-index: 210;

  @include mixin.media-all-pc {
    height: mixin.$height_pc_Furniture__note;
  }

  @include mixin.media-all-sp {
    height: mixin.$height_sp_Furniture__note;
    &.message {
      top: 0;
    }
  }

  p {
    @include mixin.media-all-pc {
      width: 52rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 5.8rem;
      font-size: 1.3rem;
    }

    @include mixin.media-all-sp {
      width: 100%;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      padding-right: mixin.vw-value(50);
      padding-left: mixin.vw-value(130);
      font-size: 1.18rem;
    }

    display: flex;
    align-items: center;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    font-size: 1rem;
    line-height: 1.6;
    letter-spacing: 0.01em;

    &::before {
      @include mixin.media-all-pc {
        left: 0;
      }

      @include mixin.media-all-sp {
        left: mixin.vw-value(27);
      }

      @media print {
        display: none;
      }

      content: '';
      position: absolute;
      width: 3.5rem;
      height: 2.8rem;
      background-image: url('../../../../public/assets/img/furniture/icon/handright.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
