// ==================================================
// Project
// ==================================================

// Tutorials ----------------------------------------------------------------
// ==========================================================================

.Tutorial {
  @at-root {
    .Tutorial__container {
      overflow-y: auto;
      width: 100%;
      height: 100%;
    }
    .iframe-tutorial {
      margin-top: 30px;
    }
  }
}
