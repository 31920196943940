@use '../../../../sass/foundation/mixin';

#column_article.article_01 {
  .article__column-content table.article__sunpou-table td:last-child {
    width: 46%;
  }

  .article__column-content .article__sunpou-table,
  .article__column-content .article__sunpou-table th,
  .article__column-content .article__sunpou-table td {
    border-collapse: collapse;
    border: 2px solid mixin.$color-mediumGray;
    padding: 15px 20px;
  }

  .article__height-table {
    margin: 0 0 20px;
  }

  .article__column-content .article__height-table,
  .article__column-content .article__height-table th,
  .article__column-content .article__height-table td {
    border-collapse: collapse;
    border: 2px solid mixin.$color-mediumGray;
    padding: 10px 10px;
  }

  .article__image-w70 {
    width: 70%;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    .article__column-content .article__sunpou-table,
    .article__column-content .article__sunpou-table th,
    .article__column-content .article__sunpou-table td {
      border-collapse: collapse;
      border: 1px solid mixin.$color-mediumGray;
      padding: 3px;
      font-size: 12px;
    }

    .article__column-content .article__height-table,
    .article__column-content .article__height-table th,
    .article__column-content .article__height-table td {
      border-collapse: collapse;
      border: 1.2px solid mixin.$color-mediumGray;
      padding: 7px;
      font-size: 14px;
    }

    .article__column-content .article__height-table th,
    .article__column-content .article__height-table td {
      display: block;
      text-align: left;
      width: 100%;
    }

    .article__column-content .article__height-table th {
      background: #e5e5e5;
    }

    .article__column-content table.article__sunpou-table td:last-child {
      width: 30%;
    }

    p.article__figurement span {
      /*    font-size: 10px;*/
      font-size: 12px;
    }
  }
}
