#column_article.article_25 {
  a {
    color: #0000ee;
  }

  .article__column-content .article__font-small p {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    .article__column-content .article__font-small p {
      font-size: 9px;
    }

    a {
      font-size: 14px;
    }

    .article__content-two-col,
    .article__image-three-col {
      margin: 1rem 0;
    }

    .article__column-content .article__font-sml p {
      font-size: 11px;
    }
  }
}
