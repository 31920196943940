@use '../../../sass/foundation/mixin';

// ==================================================
// Component
// ==================================================

// Btn
// ==========================================================================

.Btn {
  @at-root {
    .Btn__pagetop {
      @include mixin.media-all-pc {
        bottom: 4.8rem;
        right: 5.4rem;
      }
      @include mixin.media-all-sp {
        bottom: 6.8rem;
        right: 1.4rem;
      }

      position: fixed;
      width: 5.5rem;
      height: 5.5rem;
      background-image: url('../../../../public//assets/img/common/icon/totop.svg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;
      z-index: 200;
    }

    .Btn__switchtgl {
      display: none;

      // add default box-sizing for this scope
      &,
      &:after,
      &:before,
      & *,
      & *:after,
      & *:before,
      & + .Btn__switchtgl-btn {
        box-sizing: border-box;
        &::selection {
          background: none;
        }
      }

      &:checked + .Btn__switchtgl-btn {
        background-color: mixin.$color-toggleGreen;
      }

      + .Btn__switchtgl-btn {
        @include mixin.media-all-pc {
          width: 7rem;
          height: 3.6rem;
        }

        @include mixin.media-all-sp {
          width: 4.8rem;
          height: 2.5rem;
        }

        outline: 0;
        display: block;
        position: relative;
        background-color: #e7e7e7;
        border-radius: 2em;
        padding: 1px;
        transition: all 0.3s ease;
        cursor: pointer;
        user-select: none;

        &:after {
          position: relative;
          display: block;
          content: '';
          width: 50%;
          height: 100%;

          border-radius: 50%;
          background-color: #fff;
          transition: all 0.2s ease;
        }

        &:before {
          position: relative;
          display: block;
          content: '';
          width: 50%;
          height: 100%;
        }

        &:after {
          left: 0;
        }

        &:before {
          display: none;
        }
      }

      &:checked + .Btn__switchtgl-btn:after {
        left: 50%;
      }
    }
  }
}
