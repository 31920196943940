#column_article.article_49 {
  .article__49-text-aline-r {
    text-align: right;
  }

  .article__figure.article__figure__49 {
    font-size: 24px;
  }

  .article__column-content .article__image-three-col-item p {
    font-size: 15px;
    line-height: 25px;
    min-height: 50px;
    display: flex;
    align-items: flex-end;
  }

  @media (max-width: 768px) {
    .mt-2-pc {
      margin-top: 0;
    }

    .article__figure.article__figure__49 {
      font-size: 18px;
    }

    .article__column-content p.thumbnail_49 span {
      font-size: 13px;
    }

    .article__column-content .article__image-three-col-item p {
      font-size: 12px;
      min-height: 30px;
      line-height: 15px;
    }
  }
}
