#column_article.article_36 {
  .article__column-title p.article_font-red {
    font-size: 20px;
  }

  .article__column-content .article__content-two-col-item p {
    font-size: 21px;
  }

  .article__column-content .article__content-two-col-item p.title02 {
    font-size: 0.8em;
  }

  @media (max-width: 768px) {
    .article__column-content .article__content-two-col-item p {
      font-size: 13px;
      line-height: 20px;
    }

    .article__column-content .article__content-two-col-item p.title02 {
      font-size: 13px;
    }

    a {
      font-size: 14px;
    }

    .article__column-title p.article_font-red {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }
}
