#column_article.article_67 {
  .section-last {
  }

  @media (max-width: 768px) {
    .section-last {
      font-size: 13px !important;
    }
  }
}
