#column_article.article_26 {
  a {
    color: #0000ee;
  }

  table th {
    font-size: 20px;
    width: 23%;
    padding: 10px 0;
    text-align: left;
  }

  table td {
    font-size: 18px;
  }

  .article__image-three-col {
    justify-content: space-between;
    align-items: flex-end;
  }

  .article__content-two-col {
    justify-content: space-between;
  }

  .article__content-two-col .article__content-two-col-item {
    width: 48%;
  }

  .article__content-two-col .article__content-two-col-item.article__right-img {
    padding: 0;
    box-sizing: border-box;
  }

  dl.article__dl-flex {
    display: flex;
    font-size: 22px;
    line-height: 40px;
  }

  dl.article__dl-flex dt {
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    a {
      font-size: 14px;
    }

    table th {
      font-size: 13px;
      display: block;
      width: 100%;
      padding: 20px 0 10px;
    }

    table td {
      font-size: 13px;
      display: block;
    }

    .ml17 {
      margin-left: 7px !important;
    }

    dl.article__dl-flex {
      display: flex;
      font-size: 14px;
      line-height: 28px;
    }
  }
}
