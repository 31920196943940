#column_article.article_03 {
  .article__image-three-col .article__image-three-col-item img {
    width: 90%;
  }

  .article__image-three-col .article__image-three-col-item {
    width: auto;
  }

  .article__content_flex_align {
    display: flex;
    align-items: flex-end;
  }

  @media (max-width: 768px) {
    .article__image-three-col .article__image-three-col-item img {
      height: 100%;
      width: 100%;
    }

    .article__content_flex_align {
      padding-bottom: 20px;
    }
  }
}
