#column_article.article_80 {
  .sp {
    display: none;
  }

  a {
    color: #0000ee;
  }

  .title_box {
    background: #95c3aa;
    color: #fff;
    text-align: center;
    padding: 0.5em 0;
  }

  .title_note {
    margin-top: 15px;
    font-size: 14px;
  }

  .article__column-content {
    border-bottom: none;
  }

  .article__column-content p {
    font-size: 20px;
    letter-spacing: 0.01em;
    line-height: 2em;
  }

  .article__column-content p.list {
    margin: 0 0 10px 0;
    line-height: 1.5em;
    /* 余分なマージンを消去 */
    padding: 0;
    /* 余分なパディングを消去 */
    padding-left: 1em;
    /* 1文字分の左パディング */
    text-indent: -1em;
    /* 最初の行だけを1文字分左に */
  }

  .article__column-content .mb-30 {
    margin-bottom: 30px;
  }

  .article__column-content .pt-10 {
    padding-top: 10px;
  }

  .article__column-content .mb-50 {
    margin-bottom: 50px;
  }

  .article__column-content .note {
    font-size: 18px;
    line-height: 1.5em;
    margin: 0 0 5px 0;
    /* 余分なマージンを消去 */
    padding: 0;
    /* 余分なパディングを消去 */
    padding-left: 1em;
    /* 1文字分の左パディング */
    text-indent: -1em;
    /* 最初の行だけを1文字分左に */
  }

  .article__column-content p.note2 {
    font-size: 16px;
    line-height: 1.5em;
    margin: 0 0 5px 0;
    /* 余分なマージンを消去 */
    padding: 0;
    /* 余分なパディングを消去 */
    padding-left: 1em;
    /* 1文字分の左パディング */
    text-indent: -1em;
    /* 最初の行だけを1文字分左に */
  }

  .article__image-three-col-item {
    width: 15%;

    .name {
      text-align: center;
      font-size: 14px;
      min-height: 10px;

      span {
        font-size: 0.8em;
        line-height: 1;
      }
    }
  }

  .article__column-content .floor_box {
    font-size: 18px;

    p {
      margin-bottom: 10px;
    }
  }

  .article__column-content .article__check {
    margin-bottom: 15px;
  }

  .article__column-content h4 {
    font-size: 1em;
    font-weight: 500;
    color: #686767;
  }

  .article__column-content p.check {
    margin-bottom: 30px;
  }

  // 表面の色や柄について
  .article__image-change_box {
    width: 38%;

    .name {
      text-align: center;
      font-size: 14px;
      min-height: 10px;

      span {
        font-size: 0.8em;
        line-height: 1;
      }
    }
  }

  // 弊社保証（table)設定
  .guarantee_table {
    width: 100%;
    border: 1px solid #707070;
    font-size: 0.8em;
    margin-bottom: 30px;

    tr {
      border: 1px solid #707070;

      th {
        border: 1px solid #707070;
        padding: 0.25em 0;
        background: #95c3aa;
        color: #fff;
      }
    }

    td {
      border: 1px solid #707070;
      padding: 5px;
      vertical-align: middle;

      ul {
        margin-left: 1em;

        li {
          text-indent: -1em;
          margin-bottom: 5px;
        }
      }
    }

    td.textC {
      text-align: center;
      line-height: 1.25em;
    }
  }

  .green {
    color: #228a52;
  }

  // 商品の特徴
  .features_list {
    margin-bottom: 50px;

    dt {
      margin-bottom: 15px;
      font-weight: bold;

      span {
        color: #95c3aa;
        margin-right: 5px;
      }
    }

    dd {
      font-size: 20px;
      letter-spacing: 0.01em;
      line-height: 1.75em;
      margin-left: 1.35em;

      .list {
        margin-left: 1em;

        li {
          text-indent: -1em;
        }
      }
    }
  }

  .led_text {
    width: 60%;
    margin-bottom: 15px;
  }

  .led_img {
    width: 200px;
    padding: 0;
  }

  @media (max-width: 768px) {
    .sp {
      display: block;
    }

    .title_box {
      background: #95c3aa;
      color: #fff;
      text-align: center;
      padding: 0.5em 0;
    }

    .title_note {
      font-size: 12px;
    }

    .article__column-content {
      margin-bottom: 30px;
    }

    .article__image-three-col-item {
      width: 20%;

      .name {
        font-size: 9px;
      }
    }

    .article__column-content p {
      font-size: 14px;
      line-height: 1.5em;
    }

    .article__column-content p.mb-30 {
      margin-bottom: 30px;
    }

    .article__column-content p.note {
      font-size: 12px;
      margin-bottom: 20px;
    }

    .article__column-content p.note2 {
      font-size: 12px;
      margin-bottom: 20px;
    }

    .article__column-content h4 {
      font-size: 0.7em;
      font-weight: 500;
      color: #686767;
    }

    a {
      font-size: 14px;
    }

    .article__content-two-col,
    .article__image-three-col {
      margin: 1rem 0;
    }

    .article__column-content .article__font-sml p {
      font-size: 11px;
    }

    // 弊社保証（table)設定
    .guarantee_table {
      width: 100%;
      border: 1px solid #707070;
      font-size: 0.6em;
      margin-bottom: 30px;

      tr {
        border: 1px solid #707070;

        th {
          border: 1px solid #707070;
          padding: 0.25em 0;
          background: #95c3aa;
          color: #fff;
        }
      }

      td {
        border: 1px solid #707070;
        padding: 5px;
        vertical-align: middle;

        ul {
          margin-left: 1em;

          li {
            text-indent: -1em;
            margin-bottom: 5px;
          }
        }
      }

      td.textC {
        text-align: center;
        line-height: 1.25em;
      }
    }

    // 商品の特徴
    .features_list {
      margin-bottom: 50px;

      dt {
        margin-bottom: 15px;
        font-weight: 500;
        font-size: 16px;

        span {
          color: #95c3aa;
          margin-right: 5px;
        }
      }

      dd {
        font-size: 14px;
        letter-spacing: 0.01em;
        line-height: 1.75em;
        margin-left: 0;

        .list {
          margin-left: 1em;

          li {
            text-indent: -1em;
          }
        }
      }
    }

    .led_text {
      width: 100%;
      margin-bottom: 15px;
    }

    .led_img {
      width: 250px;
      padding: 0;
      margin: 0 auto;
    }
  }
}
