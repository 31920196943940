#column_article.article_40 {
  .article__w-30 {
    width: 30%;
  }

  @media (max-width: 768px) {
    .article__column-title {
      margin: 50px 0 20px;
    }
  }
}
