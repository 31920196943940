#column_article.article_51 {
  .article__50-text-aline-r {
    text-align: right;
  }

  .article__content-two-col .article__content-two-col-item.article__right-img {
    text-align: left;
  }

  .article__column-content .mt-20 h3 {
    margin: 0;
  }

  .article__back {
    background: #b3b3b3;
    padding: 10px 0;
  }

  @media (max-width: 768px) {
    .mt-2-pc {
      margin-top: 0;
    }

    .article__content-two-col-item.article__img-full.ma-0 {
      margin: 0 auto;
    }
  }
}
