@use '../../../sass/foundation/mixin';

// ==================================================
// Component
// ==================================================

// Tab
// ==========================================================================
.Tab {
  @include mixin.media-only-screen-pc {
    width: calc(100vw - 27rem);
    height: 4rem;
  }

  @include mixin.media-all-sp {
    height: 3.7rem;
    transition: left 0.4s ease;
    left: 0;
    &.-slide-open {
      left: 33rem;
    }
  }

  @media print {
    width: 100%;
    height: 4rem;
  }

  display: flex;
  align-items: flex-end;
  width: 100%;
  border-bottom: 1px solid mixin.$color-mediumGray;
  background-color: #fff;
  box-shadow: 0px 5px 5px -5px rgba(136, 136, 136, 0.3);
  z-index: 30;

  .Tab__list {
    @include mixin.media-all-pc {
      width: calc(9.2rem * 5);
    }
    @include mixin.media-all-sp {
      width: 100vw;
    }

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    margin-right: auto;
    margin-left: auto;

    li {
      @include mixin.media-all-pc {
        width: 9.2rem;
        height: 4rem;
      }

      @include mixin.media-all-sp {
        width: calc(100% / 5);
        height: 3.7rem;
      }

      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      padding-top: 0.2rem;
      border-bottom: 2px solid #fff;
      color: #848484;
      text-align: center;
      line-height: 1.5;
      cursor: pointer;

      &.-current {
        border-bottom: 2px solid mixin.$color-red;
        color: mixin.$color-red;
        cursor: default;

        .Tab__name {
          font-weight: 700;
        }
      }
    }
  }

  .Tab__name {
    @include mixin.media-all-pc {
      font-size: 1.3rem;
    }

    @include mixin.media-all-sp {
      font-size: 1.1rem;
    }

    display: block;
    line-height: 1.5;
  }

  &.-furniture {
    @include mixin.media-only-screen-pc {
      top: calc(5.8rem + #{mixin.$height_pc_Furniture__note});
    }

    @include mixin.media-all-sp {
      top: calc(4.4rem + #{mixin.$height_sp_Furniture__note});
    }

    .Tab__list {
      @include mixin.media-all-pc {
        justify-content: center;
        width: 100%;
        max-width: 90rem;
      }
      @include mixin.media-all-sp {
        justify-content: flex-start;
        width: mixin.vw-value(1020);
        overflow-x: scroll;
      }

      li {
        @include mixin.media-all-pc {
          width: calc(100% / 6);
          max-width: 15rem;
          min-width: 7rem;
        }

        @include mixin.media-all-sp {
          width: mixin.vw-value(170);
        }
        position: relative;

        span {
          @include mixin.media-all-pc {
            width: 90%;
          }

          @include mixin.media-all-sp {
            width: mixin.vw-value(160);
          }

          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -webkit-text-overflow: ellipsis; /* Safari */
          -o-text-overflow: ellipsis; /* Opera */
        }
      }
    }
  }
  &.-files {
    @include mixin.media-only-screen-pc {
      position: fixed;
      top: 5.8rem;
    }

    @include mixin.media-all-sp {
      position: fixed;
      top: 4.4rem;
    }

    height: 4.2rem;

    .Tab__list {
      @include mixin.media-all-pc {
        width: 60rem;
      }

      li {
        transition:
          width 0.2s,
          max-width 0.2s;

        width: 50%;
        flex-shrink: 1;
        @include mixin.media-all-pc {
          height: 4rem;
        }

        @include mixin.media-all-sp {
          height: 3.7rem;
        }

        .SubTab {
          display: none;
          width: 0;
        }

        &.--open-sub-menu {
          width: 66%;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          color: #848484;
          cursor: initial;
          border: 0;

          .SubTab {
            width: 100%;
            display: flex;
            li {
              width: 33%;
              height: 1.6rem;
              font-size: 1.15rem;
              padding: 0;
              overflow: hidden;
              & + li {
                border-left: 1px solid #d6d6d6;
              }
              span {
                width: 90%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                -webkit-text-overflow: ellipsis; /* Safari */
                -o-text-overflow: ellipsis; /* Opera */
              }
            }
          }
        }
      }
    }
  }
}
