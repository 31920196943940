@import '../../../sass/foundation/mixin';

.TaskCard {
  cursor: pointer;

  &__checkbox {
    margin-left: 1em;
    padding-right: 1rem;

    label {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      cursor: pointer;
      transform: scale(0.9);

      span {
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: -2.4rem;
          width: 2.6rem;
          height: 2.6rem;
          border: 2px solid #d6d6d6;
          border-radius: 1.3rem;
          background-color: #fff;
          transform: translateY(-50%);
          box-sizing: border-box;
        }
      }
      .unset::before {
        border-color: #666666;
      }

      .low::before {
        border-color: #0092ff;
      }

      .middle::before {
        border-color: #12b704;
      }

      .high::before {
        border-color: #ff0000;
      }

      input {
        visibility: hidden;
        width: 2rem;
        height: 2rem;
        margin-right: 0.6rem;

        &:checked + span {
          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: -2.4rem;
            width: 2.6rem;
            height: 2.6rem;
            transform: translateY(-50%);
            box-sizing: border-box;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
        &:checked + span.unset::after {
          background-image: url('../../../../public/assets/img/tasks/icon/check_gray.svg');
        }

        &:checked + span.low::after {
          background-image: url('../../../../public/assets/img/tasks/icon/check_blue.svg');
        }

        &:checked + span.middle::after {
          background-image: url('../../../../public/assets/img/tasks/icon/check_green.svg');
        }

        &:checked + span.high::after {
          background-image: url('../../../../public/assets/img/tasks/icon/check_red.svg');
        }
      }
    }
  }

  &__content {
    position: relative;

    .card-top {
      display: flex;

      .no-edit-icon {
        margin-top: -0.5rem;
        margin-left: -2rem;
        width: 3rem;
        margin-right: 1rem;
        cursor: auto;
      }

      button {
        position: absolute;
        top: 15%;
        right: 25%;
        padding: 0.7rem;
        background-color: $color-red;
        border-radius: 0.5rem;
        cursor: pointer;

        @include media-all-sp {
          top: 55%;
          right: 2%;
        }

        span {
          font-size: 1.4rem;
          color: #fff;
          font-weight: bold;

          @include media-all-sp {
            font-size: 1rem;
          }
        }
      }
    }

    img {
      width: 3.6rem;
      padding-left: 2rem;
      cursor: pointer;
    }
    .no-pointer-img {
      cursor: auto;
    }

    &--lower {
      display: flex;
      flex-direction: row;
    }
  }

  &__add {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 2rem;
    cursor: pointer;
  }
}
