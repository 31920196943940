#column_article.article_63 {
  .article__image-container {
    margin: 10px auto 0px;
  }

  @media (max-width: 768px) {
    .article__image-container img {
      max-width: 100%;
    }

    .article__image-container {
      margin: 10px auto 0px;
    }

    .article__pb-2-sp {
      padding-bottom: 20px;
    }
  }

  .po-relative::after {
    position: absolute;
    content: '';
    background-image: url('../../../../../public/assets/img/appointments/agendas/articles/img/06/06_image-ekijouka1-4.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 26px;
    height: 35px;
    top: 50%;
    right: -26%;
    transform: translate(-50%, 0);
  }
}
