@use '../../../sass/foundation/mixin';

// ==================================================
// Component
// ==================================================

// Bar
// ==========================================================================
.Bar {
  @include mixin.media-all-pc {
    position: fixed;
    left: 0;
    width: 100vw;
    height: 5.8rem;
  }
  @include mixin.media-all-sp {
    position: fixed;
    width: 100%;
    height: 4.4rem;
  }

  @media print {
    position: relative;
  }

  border-top: 0.1rem solid mixin.$color-mediumGray;
  border-bottom: 0.1rem solid mixin.$color-mediumGray;
  background-color: #fff;
  z-index: 1000;

  &.-shadow {
    box-shadow: 0px 2px 5px rgba(136, 136, 136, 0.3);
  }

  @at-root {
    .Bar__title {
      @include mixin.media-all-pc {
        font-size: 1.8rem;
      }

      @include mixin.media-all-sp {
        font-size: 1.3rem;
      }
      font-weight: 700;
    }

    .Bar__btn-return {
      @include mixin.media-all-pc {
        left: 2.6rem;
        width: 2.9rem;
        height: 2.7rem;
        background-size: 2.1rem 1.9rem;
      }

      @include mixin.media-all-sp {
        left: 1.8rem;
        width: 2rem;
        height: 1.8rem;
        background-size: 1.6rem 1.4rem;
      }

      position: absolute;
      background-image: url('../../../../public/assets/img/common/icon/arrow_left_02.svg');
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;
    }
  }
}

aside + .main-content > .Bar {
  @include mixin.media-only-screen-pc {
    left: 27rem;
    width: calc(100vw - 27rem);
  }
}

.CreateMessage {
  .Bar {
    @include mixin.media-all-pc {
      padding-right: 2rem;
      padding-left: 2rem;
    }

    @include mixin.media-all-sp {
      padding-right: mixin.vw-value(20);
      padding-left: mixin.vw-value(20);
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Bar__nav-left {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .Bar__nav-right {
    @include mixin.media-all-pc {
      width: 7rem;
    }

    @include mixin.media-all-sp {
      width: mixin.vw-value(140);
    }

    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    color: #d0d0d0;
    text-align: right;
    font-size: 1.4rem;
    font-weight: 700;
    cursor: default;

    &.-on {
      color: #2285fa;
      cursor: pointer;
    }
  }
}
