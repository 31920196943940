@use '../../../sass/foundation/mixin';

#Column {
  background-color: mixin.$color-lightGray;
}

.Column {
  @at-root {
    .Column__title {
      @include mixin.media-all-pc {
        font-size: 1.6rem;
      }

      @include mixin.media-all-sp {
        width: 74vw;
        font-size: 3vw;
        line-height: 1.5;
      }

      font-weight: 700;
    }

    .Column__wrapper {
      @include mixin.media-all-pc {
        padding-bottom: 11.8rem;
      }
    }

    .Column__box {
      border-bottom: 1px solid mixin.$color-mediumGray;
      background-color: #fff;
    }

    .Column__approval {
      @include mixin.media-all-pc {
        width: 62.3rem;
        padding-top: 4rem;
        padding-bottom: 5rem;
        background-color: #fff;
      }

      @include mixin.media-all-sp {
        padding-top: mixin.vw-value(46);
        padding-bottom: calc(6.2vw + 4.7rem + 30px);
        background-color: mixin.$color-lightGray;
      }

      margin-right: auto;
      margin-left: auto;
      border-top: 1px solid mixin.$color-mediumGray;

      .Column__approval-inner {
        .Column__approval-btn {
          @include mixin.media-all-pc {
            width: 26rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            border: 2px solid #ff0200;
            font-size: 1.9rem;
          }
          @include mixin.media-all-sp {
            width: mixin.vw-value(420);
            padding-top: mixin.vw-value(25);
            padding-bottom: mixin.vw-value(23);
            border: 1px solid #ff0200;
            font-size: mixin.vw-value(31);
          }

          display: block;
          margin-right: auto;
          margin-left: auto;
          border-radius: 24px;
          background-color: #fff;
          color: #ff0200;
          text-align: center;
          font-weight: 700;
          cursor: pointer;

          &.-checked {
            @include mixin.media-all-pc {
              border: 2px solid mixin.$color-mediumGray;
            }
            @include mixin.media-all-sp {
              border: 1px solid mixin.$color-mediumGray;
            }

            background-color: mixin.$color-mediumGray;
            color: #333;
          }
        }

        &.-household {
          @include mixin.media-all-pc {
            width: 45rem;
          }
          @include mixin.media-all-sp {
            width: 82vw;
          }

          margin-right: auto;
          margin-left: auto;

          .Column__approval-item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & + .Column__approval-item {
              margin-top: 2.2rem;
            }

            .Column__approval-label {
              &.-red {
                @include mixin.media-all-pc {
                  background-image: url('../../../../public/assets/img/appointments/agendas/columns/column_approve_label_01_pc.svg');
                }
                @include mixin.media-all-sp {
                  background-image: url('../../../../public/assets/img/appointments/agendas/columns/column_approve_label_01_sp.svg');
                }
              }

              &.-yellow {
                @include mixin.media-all-pc {
                  background-image: url('../../../../public/assets/img/appointments/agendas/columns/column_approve_label_02_pc.svg');
                }
                @include mixin.media-all-sp {
                  background-image: url('../../../../public/assets/img/appointments/agendas/columns/column_approve_label_02_sp.svg');
                }
              }

              &.-green {
                @include mixin.media-all-pc {
                  background-image: url('../../../../public/assets/img/appointments/agendas/columns/column_approve_label_03_pc.svg');
                }
                @include mixin.media-all-sp {
                  background-image: url('../../../../public/assets/img/appointments/agendas/columns/column_approve_label_03_sp.svg');
                }
              }

              &.-blue {
                @include mixin.media-all-pc {
                  background-image: url('../../../../public/assets/img/appointments/agendas/columns/column_approve_label_04_pc.svg');
                }
                @include mixin.media-all-sp {
                  background-image: url('../../../../public/assets/img/appointments/agendas/columns/column_approve_label_04_sp.svg');
                }
              }

              &.-purple {
                @include mixin.media-all-pc {
                  background-image: url('../../../../public/assets/img/appointments/agendas/columns/column_approve_label_05_pc.svg');
                }
                @include mixin.media-all-sp {
                  background-image: url('../../../../public/assets/img/appointments/agendas/columns/column_approve_label_05_sp.svg');
                }
              }
            }
          }

          .Column__approval-label {
            @include mixin.media-all-pc {
              width: 18rem;
              height: 5.4rem;
              padding-top: 1.8rem;
            }
            @include mixin.media-all-sp {
              width: mixin.vw-value(262);
              height: mixin.vw-value(80);
              padding-top: mixin.vw-value(26);
            }

            display: flex;
            position: relative;
            padding-right: 1.5rem;
            padding-left: 1.5rem;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            span {
              width: 100%;
              color: #fff;
              font-weight: 700;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -webkit-text-overflow: ellipsis; /* Safari */
              -o-text-overflow: ellipsis; /* Opera */

              @include mixin.media-all-pc {
                font-size: 1.6rem;
              }
              @include mixin.media-all-sp {
                font-size: 3vw;
              }
            }
          }

          .Column__approval-btn {
            @include mixin.media-all-pc {
              width: 26rem;
              padding-top: 1.3rem;
              padding-bottom: 1.3rem;
              border: 2px solid #ff0200;
              border-radius: 16px;
              font-size: 2rem;
            }

            @include mixin.media-all-sp {
              width: 44vw;
              padding-top: mixin.vw-value(25);
              padding-bottom: mixin.vw-value(23);
              border: 1px solid #ff0200;
              border-radius: 8px;
              font-size: 4vw;
            }

            display: block;
            background-color: #fff;
            color: #ff0200;
            text-align: center;
            font-weight: 700;
            cursor: pointer;

            &.-checked {
              @include mixin.media-all-pc {
                border: 2px solid mixin.$color-mediumGray;
              }

              @include mixin.media-all-sp {
                border: 1px solid mixin.$color-mediumGray;
              }

              background-color: mixin.$color-mediumGray;
              color: #333;
            }
          }
        }
      }

      .Column__completion-inner {
        display: flex;
        flex-direction: column;
        align-items: center;

        .Column__completion-checkbox {
          display: flex;
          justify-content: center;

          label {
            display: flex;
            align-items: center;

            span {
              position: relative;
              font-size: 2.5rem;
              font-weight: bold;
              color: mixin.$color-red;

              &::before {
                background-image: url('../../../../public/assets/img/common/icon/square_check_red.svg');
                background-repeat: no-repeat;
                background-size: contain;
                content: '';
                position: absolute;
                top: 50%;
                left: -7rem;
                width: 5rem;
                height: 5rem;
                transform: translateY(-50%);
                display: block;
                cursor: pointer;
              }
            }

            input {
              visibility: hidden;
              width: 5rem;
              height: 5rem;
              margin-right: 1.5rem;

              &:checked + span {
                &::after {
                  content: '';
                  display: block;
                  position: absolute;
                  top: 50%;
                  left: -7rem;
                  width: 5rem;
                  height: 5rem;
                  transform: translateY(-50%);
                  background-image: url('../../../../public/assets/img/common/icon/square_checked_red.svg');
                  background-repeat: no-repeat;
                  background-size: contain;
                  cursor: default;
                }
              }
            }
          }
        }

        .Column__question-item {
          width: 90%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 2rem;

          .Column__question-text {
            padding-top: 0.5rem;
            font-size: 1.5rem;

            .Column__question-link {
              cursor: pointer;
              color: mixin.$color-blue;
              font-weight: bold;
              padding: 0 0.3rem;
            }
          }
        }
      }

      .button-list {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        margin-top: 10%;
        @include mixin.media-all-sp {
          gap: 3vh;
        }
        .invisible {
          visibility: hidden;
          pointer-events: none;
        }

        .prev-button,
        .next-button,
        .column-request-button {
          color: #fff;
          cursor: pointer;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          padding-top: 2.8rem;
          padding-bottom: 1rem;
          @include mixin.media-all-sp {
            padding-bottom: 4vh;
          }
        }
        .prev-button {
          background-image: url('../../../../public/assets/img/appointments/agendas/columns/prev.svg');
          width: 8em;
          @include mixin.media-all-sp {
            width: 15vw;

            min-width: 6.5em;
          }
        }

        .next-button {
          background-image: url('../../../../public/assets/img/appointments/agendas/columns/next.svg');
          width: 8em;
          @include mixin.media-all-sp {
            width: 15vw;
            min-width: 6.5em;
          }
        }

        .column-request-button {
          background-image: url('../../../../public/assets/img/appointments/agendas/columns/column_request.svg');
          width: 18em;
          @include mixin.media-all-sp {
            width: 33vw;
            min-width: 13em;
          }
        }
      }
    }

    .Column__none {
      @include mixin.media-all-pc {
        height: calc(100vh - 38.8rem);
      }

      @include mixin.media-all-sp {
        height: calc(100vh - 29.8rem);
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: mixin.$color-lightGray;

      p {
        @include mixin.media-all-pc {
          font-size: 2rem;
        }

        @include mixin.media-all-sp {
          font-size: 1.6rem;
        }

        color: #717171;
        font-weight: 700;
      }
    }
  }
}
