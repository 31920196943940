#column_article.article_52 {
  .article__50-text-aline-r {
    text-align: right;
  }

  .article__50-text-aline-l {
    text-align: left;
  }

  .article__51-text-c {
    text-align: center;
  }

  .article__50-clear-both {
    clear: both;
  }

  .article__51-page1-box-item {
    margin-top: 20px;
    padding-bottom: 50px;
  }

  .article__51-page1-box-item1 {
    text-align: center;
    padding: 0 10px;
  }

  .article__51-page1-box-item1 h3 {
    max-width: 30%;
    font-size: 26px;
    font-weight: bold;
    border-bottom: solid 2px #e0815e;
    padding: 0 10px 5px;
    margin: 0 auto;
  }

  .article__51-page1-box-item1 img {
    width: 70px;
  }

  .article__51-page3-box {
    margin: 20px 0;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .article__51-page3-box-item1 {
    width: 100%;
    padding-bottom: 10px;
  }

  .article__51-page3-box-item2 {
    width: 100%;
  }

  .article__51-page3-box-item2 img {
    float: left;
    width: 35%;
    padding-bottom: 50px;
  }

  .article__content-two-col
    .article__content-two-col-item.article__img-size-ori-1-1 {
    width: 65.5%;
  }

  .article__content-two-col
    .article__content-two-col-item.article__img-size-ori-1-2 {
    width: 34.5%;
  }

  .article__content-two-col .article__content-two-col-item.article__right-img {
    padding: 0;
  }

  .article__content-two-col
    .article__content-two-col-item.article__img-size-ori-2-1 {
    width: 68%;
    display: flex;
    align-items: flex-end;
  }

  .article__content-two-col
    .article__content-two-col-item.article__img-size-ori-2-2 {
    width: 28%;
    display: flex;
    align-items: flex-end;
  }

  .article__content-two-col
    .article__content-two-col-item.article__img-size-ori-2-1
    img,
  .article__content-two-col
    .article__content-two-col-item.article__img-size-ori-2-2
    img {
    height: auto;
    width: 100%;
  }

  .article__content-two-col.article__img-size-ori {
    justify-content: space-between;
  }

  .article__out {
    width: 100%;
    margin: 10px 0 0;
  }

  .article__image-two-col-side + .article__image-two-col-side img {
    margin-top: 3px;
  }

  @media (max-width: 768px) {
    .article__51-page1-box-item1 h3 {
      font-size: 16px;
    }

    .article__51-page1-box-item1 p.article__51-influ {
      text-align: center;
      line-height: 20px;
      padding: 10px;
    }

    .article__content-two-col
      .article__content-two-col-item.article__img-size-ori-1-1 {
      width: 65%;
    }

    .article__content-two-col
      .article__content-two-col-item.article__img-size-ori-1-2 {
      width: 34%;
    }

    .article__content-two-col
      .article__content-two-col-item.article__img-size-ori-1-2
      img {
      max-width: 600px;
    }
  }

  @media (max-width: 768px) {
    .article__51-page3-box-item2 p.article__51-page3-box-item2_width_100 {
      width: 100%;
    }

    .article__content-two-col-item.article__img-full {
      margin: 0 auto 5px;
    }

    .article__image-two-col-side .article__image-two-col-item p.ma-0minus {
      margin-top: -7px;
    }

    .article__pb-20-sp {
      padding-bottom: 20px;
    }
  }
}
