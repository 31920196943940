@import '../../../sass/foundation/mixin';
// ==================================================
// Project
// ==================================================

// SystemMessage
// ==========================================================================

.SystemMessage__item {
  @include media-all-pc {
    padding-top: 2rem;
    padding-right: 1.8rem;
    padding-left: 1.8rem;
    padding-bottom: 2.3rem;
    font-size: 1.4rem;
    width: 62.4rem;
    margin-left: auto;
    margin-right: auto;
  }

  @include media-screen-sp {
    padding-top: 1.2rem;
    padding-right: vw-value(19);
    padding-left: vw-value(19);
    padding-bottom: 1.5rem;
    font-size: 1.3rem;
  }

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #d5d5d5;

  &.-info {
    @include media-screen-sp {
      padding-right: vw-value(30);
      padding-left: vw-value(30);
    }

    .SystemMessage__item-wrap {
      @include media-all-pc {
        padding-top: 2rem;
        padding-right: 2rem;
        padding-left: 2rem;
        padding-bottom: 1.8rem;
        border: 2px solid #ff8888;
      }

      @include media-screen-sp {
        padding-top: 1rem;
        padding-right: 1.2rem;
        padding-left: 1.2rem;
        padding-bottom: 1rem;
        border: 1px solid #ff8888;
      }

      width: 100%;
      border-radius: 0.6rem;

      .SystemMessage__item-title {
        @include media-all-pc {
          font-size: 1.9rem;
        }
        @include media-screen-sp {
          font-size: vw-value(24);
        }

        margin-bottom: 0.8em;
        color: #3d3d3d;
        text-align: center;
        font-weight: 700;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }

      .SystemMessage__item-message {
        @include media-all-pc {
          font-size: 1.6rem;
        }
        @include media-screen-sp {
          font-size: vw-value(21);
        }

        color: #3d3d3d;
        line-height: 1.4;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: pre-line;
        margin-bottom: 2.4rem;

        & + .SystemMessage__item-message {
          @include media-all-pc {
            margin-top: 2em;
          }
          @include media-screen-sp {
            margin-top: 1.5em;
          }
        }

        a {
          color: #0000ee;
        }
      }
    }

    .SystemMessage__item-wrap :last-child {
      margin-bottom: 0;
    }
  }
}
