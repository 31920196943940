// ==================================================
// Project
// ==================================================

// Keeps ----------------------------------------------------------------
// ==========================================================================

.Keep {
  @at-root {
    .Keep__container {
      overflow-y: auto;
      width: 100%;
      height: 100%;
    }
    .iframe-keep {
      margin-top: 40px;
    }
  }
}
