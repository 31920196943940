#column_article.article_70 {
  .article__content-two-col .article__content-two-col-item {
    width: 60%;
  }

  .article__content-two-col .article__content-two-col-item.article__right-img {
    width: 40%;
  }

  .article__column-title h2 {
    letter-spacing: -0.01em;
    font-size: 30px;
  }

  .article__column-title .main_text {
    margin-bottom: 40px;
  }

  .article__column-title h2 span {
    bottom: 5px;
    left: 70px;
    font-size: 19px;
    color: #000;
    margin-left: 20px;
  }

  .article__column-content {
    border-bottom: none;
    padding: 20px 0 0;

    ul {
      li {
        line-height: 1.75em;

        a {
          color: #00e;
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .article__column-inner {
      margin-bottom: 18px;
    }

    .article__column-title h2 {
      letter-spacing: 0;
      font-size: 18px;
    }

    .article__column-content {
      border-bottom: none;
      padding: 10px 0 0;

      ul {
        li {
          line-height: 1.75em;
          font-size: 14px;

          a {
            color: #00e;
            text-decoration: underline;
          }
        }
      }
    }

    .article__column-title {
      margin: 30px 0 0;
    }
  }
}
