#column_article.article_18 {
  .article__image-container.article__image-center img {
    width: 50%;
  }

  .article__image-container {
    margin: 30px auto;
  }

  .article__column-content h3 {
    padding: 0 25px 0 35px;
    position: relative;
    line-height: 1.25em;
  }

  @media (max-width: 768px) {
    .article__image-container.article__image-center img {
      width: 70%;
    }

    .article__column-content h3 span {
      font-size: 14px;
    }

    .article__column-content p {
      padding-left: 15px;
    }
  }
}
