@use '../../../sass/foundation/mixin';

// ==================================================
// Project
// ==================================================

// Schedule
// ==========================================================================

.Schedule {
  @at-root {
    .Schedule__calendar {
      @media print {
        width: 100%;
      }

      background-color: #fff;
      z-index: 50;

      .Schedule__calendar-inner {
        @include mixin.media-all-pc {
          width: 62.4rem;
        }

        @include mixin.media-all-sp {
          width: 90%;
        }

        height: 100%;
        margin-right: auto;
        margin-left: auto;
      }

      @at-root .Schedule__calendar-controller {
        @include mixin.media-all-pc {
          height: 3.7rem;
        }

        @include mixin.media-all-sp {
          height: 3.2rem;
        }

        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        width: 100%;
        border-top: 1px solid mixin.$color-mediumGray;
        border-bottom: 1px solid mixin.$color-mediumGray;
        background-color: #fff;
        box-shadow: 0px 2px 5px rgba(136, 136, 136, 0.3);
        z-index: 100;

        .Schedule__calendar-btn {
          @include mixin.media-all-pc {
            width: 4rem;
            height: 2rem;
          }

          @include mixin.media-all-sp {
            width: 4rem;
            height: 2rem;
          }

          background-image: url('../../../../public/assets/img/schedule/icon/cal_btn.svg');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }

    .Schedule__event {
      @include mixin.media-all-pc {
        height: calc(100% - 6rem);
        padding-bottom: 4rem;
      }

      @include mixin.media-all-sp {
        height: calc(100% - 5rem);
        padding-bottom: 4rem;
      }

      .Schedule__event-inner {
        @include mixin.media-all-pc {
          width: 62.5rem;
          margin-right: auto;
          margin-left: auto;
        }
      }

      @at-root {
        .Schedule__event-item {
          @include mixin.media-all-pc {
            padding-top: 1.2rem;
            padding-bottom: 2rem;
          }

          @include mixin.media-all-sp {
            padding-top: mixin.vw-value(22);
            padding-bottom: mixin.vw-value(24);
          }

          display: block;
          border-bottom: 1px solid mixin.$color-mediumGray;
        }
        .Schedule__event-item-extradition {
          display: flex;
          align-items: center;
          position: relative;
          padding-top: 2.6rem;
          padding-bottom: 2.4rem;
          border-bottom: 1px solid mixin.$color-mediumGray;
          background-color: mixin.$color-lightBlue;

          &::before {
            @include mixin.media-all-pc {
              left: 1.8rem;
            }

            @include mixin.media-all-sp {
              left: 1.4rem;
            }

            content: '';
            position: absolute;
            width: 5.6rem;
            height: 5.6rem;
            background-image: url('../../../../public/assets/img/schedule/icon/extradition.svg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
          }

          .Schedule__event-label-extradition {
            @include mixin.media-all-pc {
              padding-left: 8.6rem;
            }

            @include mixin.media-all-sp {
              padding-left: 8.4rem;
            }

            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-right: 1rem;
          }

          .Schedule__event-label-flag {
            @include mixin.media-all-pc {
              max-width: 425px;
            }
            @include mixin.media-all-sp {
              max-width: 44vw;
            }
          }

          .label-date {
            font-size: 1.3rem;
            font-weight: 700;
          }

          .label-text {
            margin-top: 0.2rem;
            font-size: 1.8rem;
            font-weight: 700;
            line-height: 1.6;
          }
        }

        .Schedule__event-item-contract {
          display: flex;
          align-items: center;
          position: relative;
          padding-top: 2.6rem;
          padding-bottom: 2.4rem;
          border-bottom: 1px solid mixin.$color-mediumGray;
          background-color: mixin.$color-pink;

          &::before {
            @include mixin.media-all-pc {
              left: 1.8rem;
            }

            @include mixin.media-all-sp {
              left: 1.4rem;
            }

            content: '';
            position: absolute;
            width: 5.6rem;
            height: 5.6rem;
            background-image: url('../../../../public/assets/img/schedule/icon/contract.svg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
          }

          .Schedule__event-label-contract {
            @include mixin.media-all-pc {
              padding-left: 8.6rem;
            }

            @include mixin.media-all-sp {
              padding-left: 8.4rem;
            }

            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-right: 1rem;
          }

          .label-date {
            font-size: 1.3rem;
            font-weight: 700;
          }

          .label-text {
            @include mixin.media-all-pc {
              min-width: 11rem;
            }
            @include mixin.media-all-pc {
              min-width: 25vw;
            }

            margin-top: 0.2rem;
            font-size: 1.8rem;
            font-weight: 700;
            line-height: 1.6;
          }
        }

        .Schedule__event-label {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &.-none {
            justify-content: flex-end !important;
          }

          @at-root {
            .Schedule__event-label-date {
              @include mixin.media-all-pc {
                width: 13.4rem;
                padding-top: 0.5rem;
                padding-right: 1rem;
                padding-left: 1.2rem;
                padding-bottom: 0.4rem;
                font-size: 1.3rem;
                font-weight: 700;
              }

              @include mixin.media-all-sp {
                width: mixin.vw-value(256);
                height: mixin.vw-value(44);
                padding-top: mixin.vw-value(6);
                padding-right: mixin.vw-value(10);
                padding-left: mixin.vw-value(12);
                padding-bottom: mixin.vw-value(6);
                font-size: mixin.vw-value(28);
              }

              flex-shrink: 0;
              position: relative;
              color: #fff;

              &.-red {
                background-color: mixin.$color-red;
                &.-past {
                  background-color: #fc9d9d;
                }
              }

              &.-blue {
                background-color: mixin.$color-blue;
              }

              &::after {
                @include mixin.media-all-pc {
                  top: 0;
                  right: 0;
                  border-top: 1.2rem solid transparent;
                  border-right: 0.8rem solid #fff;
                  border-bottom: 1.2rem solid transparent;
                }

                @include mixin.media-all-sp {
                  top: -0.1rem;
                  right: -0.1rem;
                  border-top: mixin.vw-value(24) solid transparent;
                  border-right: mixin.vw-value(15) solid #fff;
                  border-bottom: mixin.vw-value(24) solid transparent;
                }

                content: '';
                position: absolute;
              }
            }

            .Schedule__event-label-flag {
              @include mixin.media-all-pc {
                margin-right: 0.6rem;
              }

              @include mixin.media-all-sp {
                margin-right: mixin.vw-value(18);
              }

              display: flex;
              flex-wrap: wrap;
              overflow: hidden;

              img {
                @include mixin.media-all-pc {
                  width: 1.5rem;
                  height: 2.1rem;
                  margin-top: 0.6rem;
                  margin-right: 0.5rem;
                  margin-left: 0.5rem;
                }

                @include mixin.media-all-sp {
                  width: mixin.vw-value(22);
                  height: mixin.vw-value(30);
                  margin-top: mixin.vw-value(8);
                  margin-right: mixin.vw-value(8);
                  margin-left: mixin.vw-value(8);
                }
              }
            }
          }
        }

        .Schedule__event-title {
          @include mixin.media-all-pc {
            margin-top: 1.6rem;
            font-size: 1.8rem;
          }

          @include mixin.media-all-sp {
            margin-top: mixin.vw-value(24);
            margin-left: mixin.vw-value(23);
            font-size: mixin.vw-value(36);
          }

          font-weight: 700;
          line-height: 1.5;
          word-wrap: break-word;
          overflow-wrap: break-word;
        }

        .Schedule__event-desc {
          @include mixin.media-all-pc {
            margin-top: 1.6rem;
          }

          @include mixin.media-all-sp {
            margin-top: mixin.vw-value(24);
            margin-right: mixin.vw-value(23);
            margin-left: mixin.vw-value(23);
          }

          .text-place {
            display: flex;
            align-items: center;
          }

          span {
            @include mixin.media-all-pc {
              font-size: 1.4rem;
            }

            @include mixin.media-all-sp {
              font-size: mixin.vw-value(26);
            }

            font-weight: 400;
            line-height: 1.5;
            word-wrap: break-word;
            overflow-wrap: break-word;
            max-width: 80%;
            display: inline-block;
          }

          &.-conference {
            margin-top: 1rem;
          }

          &.-link {
            line-height: 2;
            padding-top: 0.5rem;

            .icon-link {
              width: 200px;
            }
          }

          &.-url {
            display: block;
            font-weight: 500;
            margin-top: 0;
          }

          .Schedule__event-household-list {
            li {
              font-size: 1.4rem;
              font-weight: 700;
              line-height: 1.5;
            }
          }
        }

        .Schedule__event-flex-box {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
    }

    .Schedule__milestone {
      @include mixin.media-all-pc {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
      }
      @include mixin.media-all-sp {
        padding-top: mixin.vw-value(35);
        padding-bottom: mixin.vw-value(35);
      }

      border-bottom: 1px solid mixin.$color-mediumGray;

      @at-root {
        .Schedule__milestone-label {
          @include mixin.media-all-pc {
            width: 35rem;
            padding-top: 1.6rem;
            padding-right: 3.1rem;
            padding-left: 6rem;
            padding-bottom: 1.5rem;
            border-radius: 2.4rem;
            font-size: 1.5rem;
          }

          @include mixin.media-all-sp {
            width: mixin.vw-value(698);
            padding-top: 1.6rem;
            padding-right: mixin.vw-value(56);
            padding-left: mixin.vw-value(110);
            padding-bottom: 1.5rem;
            border-radius: mixin.vw-value(50);
            font-size: mixin.vw-value(28);
          }

          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          margin-right: auto;
          margin-left: auto;
          border: 1px solid mixin.$color-mediumGray;
          background-color: mixin.$color-lightGray;
          color: #333333;

          & + .Schedule__milestone-label {
            margin-top: 2.2rem;
          }

          &::before {
            position: absolute;
            left: mixin.vw-value(55);
            content: '';
            width: 1.8rem;
            height: 2.1rem;
            margin-top: -0.2rem;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
          }

          &.-icon-star::before {
            @include mixin.media-all-pc {
              left: 2.5rem;
            }

            @include mixin.media-all-sp {
              left: mixin.vw-value(44);
            }

            background-image: url('../../../../public/assets/img/schedule/icon/label_star.svg');
          }

          &.-icon-house::before {
            @include mixin.media-all-pc {
              left: 2.5rem;
            }

            @include mixin.media-all-sp {
              left: mixin.vw-value(44);
            }

            background-image: url('../../../../public/assets/img/schedule/icon/label_house_blue.svg');
          }

          span {
            font-weight: 700;
          }
        }
      }
    }
  }
}
