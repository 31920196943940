#column_article.article_38 {
  .article__column-title p {
    font-size: 20px;
  }

  .article__column-content p {
    font-size: 19px;
  }

  @media (max-width: 768px) {
    .article__column-title {
      margin: 50px 0 20px;
    }

    a {
      font-size: 14px;
    }

    .article__column-title p {
      font-size: 14px;
    }

    .article__column-content p {
      font-size: 12px;
    }
  }
}
