#column_article.article_48 {
  .article__48-line-height-40 {
    line-height: 40px;
  }

  .article__figure.article__figure__48 {
    font-size: 28px;
  }

  .article__column-content .article__thumbnail__48 {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    .article__48-line-height-40 {
      line-height: 25px;
      font-size: 14px;
    }

    .article__figure.article__figure__48 {
      font-size: 17px;
    }

    .article__image-three-col .article__image-three-col-item {
      width: 50%;
    }

    .article__column-content .article__thumbnail__48 {
      font-size: 14px;
    }
  }
}
