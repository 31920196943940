#column_article.article_22 {
  .article__content-two-col.exterior
    .article__content-two-col-item.article__title-img {
    width: 29%;
  }

  .article__content-two-col.exterior .article__content-two-col-item {
    width: 71%;
  }

  .article__content-two-col.exterior
    .article__content-two-col-item
    .second
    .article__content-two-col-item {
    width: 50%;
    padding: 0;
    margin: 0;
  }

  .article__content-two-col.title-area {
    justify-content: left;
  }

  .article__content-two-col.title-area .article__content-two-col-item.title {
    width: 36%;
  }

  .article__image-four-col .article__image-four-col-item.article__ori-size-1-1 {
    width: 20%;
  }

  .article__image-four-col .article__image-four-col-item.article__ori-size-1-2 {
    width: 23%;
  }

  .article__image-four-col .article__image-four-col-item.article__ori-size-1-3 {
    width: 27%;
  }

  .article__image-four-col .article__image-four-col-item.article__ori-size-1-4 {
    width: 17%;
  }

  .article__content-two-col.title-area .article__content-two-col-item {
    width: 64%;
  }

  @media (max-width: 768px) {
    .article__content-two-col.exterior
      .article__content-two-col-item.article__title-img {
      align-self: center;
    }

    .article__content-two-col.title-area .article__content-two-col-item.title,
    .article__content-two-col.title-area .article__content-two-col-item {
      width: 100%;
    }

    .article__image-four-col .article__image-four-col-item {
      margin: 0 0 40px;
    }

    .article__image-four-col
      .article__image-four-col-item.article__ori-size-1-1 {
      width: 40%;
    }

    .article__image-four-col
      .article__image-four-col-item.article__ori-size-1-2 {
      width: 46%;
    }

    .article__image-four-col
      .article__image-four-col-item.article__ori-size-1-3 {
      width: 59%;
    }

    .article__image-four-col
      .article__image-four-col-item.article__ori-size-1-4 {
      width: 29%;
    }
  }
}
