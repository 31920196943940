#column_article.article_09 {
  .article__image-container.article__image-top {
    margin: 0 0 1rem;
  }

  .article__image-container.article__image-top img {
    width: 75%;
  }

  .article__column-content .second h3 {
    padding: 0 0 0 40px;
    margin-top: 0;
  }

  .article__image-four-col-item p {
    text-align: center;
    font-size: 16px;
  }

  .article__content-two-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .article__content-two-col.article__content-two-col-ori
    .article__content-two-col-item:nth-of-type(1) {
    width: 70%;
  }

  .article__content-two-col.article__content-two-col-ori
    .article__content-two-col-item:nth-of-type(2) {
    width: 30%;
  }

  .article__content-two-col.second {
    margin-top: 1rem;
  }

  .article__column-content h3 {
    padding: 0 0 0 40px;
    font-size: 24px;
  }

  @media (max-width: 768px) {
    .article__column-content h3 {
      padding: 0 0 0 35px;
      font-size: 16px;
    }
  }
}
