#column_article.article_07 {
  .article__content-two-col .article__content-two-col-item {
    width: 55%;
  }

  .article__content-two-col .article__content-two-col-item.article__right-img {
    width: 45%;
  }

  .article__column-title p {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    .article__content-two-col .article__content-two-col-item,
    .article__content-two-col
      .article__content-two-col-item.article__right-img {
      width: 100%;
    }

    .article__image-container img {
      width: 100%;
      max-width: 400px;
    }

    .pt-20 {
      padding-top: 0px;
    }

    .article__column-title p {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
