.Slide {
  @media screen and (min-width: 769px) {
    height: 100vh;
  }
  position: relative;

  .Slide__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .Slide__arrows {
    @media screen and (min-width: 769px) {
      width: 62.4rem;
    }

    @media screen and (max-width: 768px) {
      width: 90vw;
    }

    z-index: 20;
    position: relative;
    display: flex;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 48px;
  }

  .Slide__arrowsback {
    margin-right: auto;
  }

  .Slide__arrowsforward {
    margin-left: auto;
  }

  /* ANIMATIONS */
  .slide-enter {
    transform: translateX(100%);
  }
  .slide-enter-active {
    transform: translateX(0%);
    transition: transform 250ms ease-in-out;
  }
  .slide-exit {
    transform: translateX(0%);
  }
  .slide-exit-active {
    transform: translateX(-100%);
    transition: transform 250ms ease-in-out;
  }

  .rev-enter {
    transform: translateX(-100%);
  }
  .rev-enter-active {
    transform: translateX(0%);
    transition: transform 250ms ease-in-out;
  }
  .rev-exit {
    transform: translateX(0%);
  }
  .rev-exit-active {
    transform: translateX(100%);
    transition: transform 250ms ease-in-out;
  }
}
