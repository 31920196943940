@use '../../../../sass/foundation/mixin';

/* ==============コラム共通スタイル ============
============================================= */
#column_article {
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 22px;
  letter-spacing: 0;

  .article_font-red {
    color: red;
  }

  .article__pc {
    display: block;
  }

  .article__sp {
    display: none;
  }

  .article__column-inner {
    width: 623px;
    margin: 0 auto;
  }

  /* === コラムタイトル === */
  .article__column-title {
    margin: 50px 0 0;
  }

  .article__column-title h2 {
    position: relative;
    padding: 0 0 15px 70px;
    border-bottom: 5px solid mixin.$color-mediumGray;
    letter-spacing: 2px;
    font-size: 30px;
    margin: 1rem 0;
  }

  .article__column-title h2:first-of-type {
    margin: 2rem 0 1rem;
  }

  .article__column-title h2.article__figure::before {
    position: absolute;
    content: url('../../../../../public/assets/img/appointments/agendas/articles/article_svg/figure.svg');
    width: 55px;
    left: 2px;
    top: -3px;
  }

  .article__column-title p {
    font-size: 22px;
    line-height: 40px;
    margin: 0;
  }

  .article__image-some-col-item.article__image-some-col-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .article__image-some-col-item img {
    width: 100%;
  }

  .article__image-some-col-item.article__image-some-col-flex img {
    width: 45%;
  }

  .article__image-two-col,
  .article__image-three-col,
  .article__image-four-col {
    display: flex;
    justify-content: space-around;
    margin: 0 0 20px;
  }

  .article__image-three-col.item-2-3 {
    justify-content: left;
  }

  .article__image-two-col .article__image-two-col-item {
    width: 45%;
  }

  .article__image-three-col .article__image-three-col-item {
    width: 30%;
  }

  .article__image-four-col .article__image-four-col-item {
    width: 22%;
  }

  .article__image-three-col .article__image-three-col-item.item-2-3 {
    padding: 0;
    width: 30%;
  }

  .article__image-two-col .article__image-two-col-item img,
  .article__image-three-col .article__image-three-col-item img,
  .article__image-four-col .article__image-four-col-item img {
    width: 100%;
  }

  .article__image-two-col-side,
  .article__image-three-col-side {
    display: flex;
    justify-content: space-between;
  }

  .article__image-two-col-side .article__image-two-col-item {
    width: 47%;
  }

  .article__image-three-col-side .article__image-three-col-item {
    width: 30%;
  }

  .article__image-two-col-side .article__image-two-col-item p,
  .article__image-three-col-side .article__image-three-col-item p {
    margin: 5px auto;
  }

  .article__image-two-col-side .article__image-two-col-item img,
  .article__image-three-col-side .article__image-three-col-item img {
    width: 100%;
  }

  .article__image-two-col-4-6 {
    display: flex;
    justify-content: space-between;
    margin: 50px auto 0;
  }

  .article__image-two-col-4-6 img {
    display: flex;
    justify-content: space-between;
  }

  .article__image-two-col-4-6 .article__image-two-col-item-left {
    width: 40%;
  }

  .article__image-two-col-4-6 .article__image-two-col-item-left img {
    width: 100%;
  }

  .article__image-two-col-4-6 .article__image-two-col-item-right {
    width: 55%;
  }

  .article__image-two-col-4-6 .article__image-two-col-item-right img {
    width: 100%;
  }

  .article__image-two-col-center {
    display: flex;
    justify-content: center;
    margin: 20px auto;
  }

  .article__image-two-col-center .article__image-two-col-item {
    width: 35%;
  }

  .article__image-two-col-center .article__image-two-col-item p {
    margin: 5px auto;
  }

  .article__image-two-col-center .article__image-two-col-item img {
    width: 100%;
  }

  .article__content-two-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .article__content-two-col .article__content-two-col-item {
    width: 50%;
  }

  .article__content-two-col .article__content-two-col-item.article__right-img {
    padding: 0 15px;
    box-sizing: border-box;
  }

  .article__content-two-col-item.article__img-full img {
    width: 100%;
  }

  .article__image-two-col .article__image-two-col-item img {
    width: 100%;
  }

  .article__image-two-col-side
    .article__image-two-col-item
    .article__image-original
    img {
    width: 83.5%;
  }

  .pl-15 {
    padding-left: 10px;
  }

  .ma-0 {
    margin: 0 auto;
  }

  /* === コラムタイトル SP === */
  @media (max-width: 768px) {
    .article__pc {
      display: none;
    }

    .article__sp {
      display: block;
    }

    .article__inner {
      max-width: 750px;
      width: 90%;
      margin: 0 auto;
    }

    .article__column-inner {
      width: 100%;
      max-width: 730px;
      margin: 0 auto;
    }

    .article__column-title h2 {
      padding: 0 0px 10px 45px;
      border-bottom: 1px solid mixin.$color-mediumGray;
      letter-spacing: 2px;
      font-size: 18px;
    }

    .article__column-title h2:first-of-type {
      margin: 1rem 0;
    }

    .article__column-title h2.article__figure::before {
      position: absolute;
      content: url('../../../../../public/assets/img/appointments/agendas/articles/article_svg/figure.svg');
      width: 35px;
      left: 2px;
      top: 0;
    }

    .article__column-content h3.article__check::before {
      width: 20px;
      left: 2px;
      top: 4px;
    }

    .article__column-content h3 {
      padding: 0 25px 0;
      font-size: 16px;
    }

    .article__column-title p {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }

    .article__column-title p.article__no-title {
      margin: 0 0 1rem;
    }

    .pl-15 {
      padding-left: 2%;
    }
  }

  /* === コラムコンテンツ === */

  .article__column-content {
    padding: 20px 0 40px;
    border-bottom: 2px solid mixin.$color-mediumGray;
  }

  .article__column-content:last-child {
    border-bottom: none;
  }

  .article__column-content.last {
    border-bottom: none;
  }

  .article__column-content h3 {
    position: relative;
    padding: 0 40px 0;
    font-size: 26px;
    margin: 20px 0 30px;
  }

  .article__column-content h3.article__check::before {
    position: absolute;
    content: url('../../../../../public/assets/img/appointments/agendas/articles/article_svg/check.svg');
    width: 30px;
    left: 2px;
    top: 0;
  }

  .article__column-content p {
    min-height: 40px;
    font-size: 22px;
    line-height: 40px;
  }

  .article__column-content p.article__text-only {
    margin: 0 0 1rem;
  }

  .article__image-container {
    margin: 30px auto;
    text-align: center;
  }

  .article__image-container.article__image-center {
    text-align: center;
  }

  .article__image-container.article__image-center img {
    width: 70%;
  }

  .article__column-content p.article__figurement {
    position: relative;
  }

  .article__column-content p.article__figurement.row-2 {
    position: relative;
    min-height: 75px;
  }

  .article__column-content p.article__figurement.row-3 {
    position: relative;
    min-height: 75px;
  }

  .article__column-content p.article__figurement.row-2 span,
  .article__column-content p.article__figurement.row-3 span {
    position: absolute;
    bottom: 0;
  }

  .article__column-content p.article__figurement span.article__sentense-bottom {
    top: 0;
  }

  .br_pc {
    display: block;
  }

  .wa-70 {
    max-width: 70%;
  }

  .article__column-content span.article__pdf-icon {
    position: relative;
  }

  .article__column-content span.article__pdf-icon::after {
    position: absolute;
    content: url('../../../../../public/assets/img/appointments/agendas/articles/img/18/18_image-pdf00.svg');
    width: 30px;
    right: -32px;
    top: 0px;
  }

  /* === コラムコンテンツSP === */
  @media (max-width: 768px) {
    .article__column-content span.article__pdf-icon::after {
      width: 20px;
      right: -20px;
      top: -4px;
    }

    .br_pc {
      display: none;
    }

    .mh-40 {
      min-height: 40px;
    }

    .article__image-container {
      margin: 20px auto;
    }

    .article__image-container img {
      width: 100%;
      max-width: 400px;
    }

    .article__column-content p {
      min-height: 20px;
      font-size: 14px;
      line-height: 15px;
      text-align: left;
    }

    .article__column-content p.sub_title {
      font-size: 12px;
      letter-spacing: -0.05em;
      padding-top: 5px;
    }

    .article__inner {
      max-width: 750px;
      width: 90%;
      margin: 0 auto;
    }

    .article__column-content h3.article__check::before {
      width: 20px;
      left: 10px;
      top: -2px;
    }

    .article__column-content h3 {
      padding: 0 0 0 35px;
      font-size: 16px;
    }

    .article__column-title p {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }

    .article__column-content {
      padding: 0;
    }

    .article__image-two-col-4-6 {
      margin: 20px auto;
    }

    .article__image-three-col {
      flex-wrap: wrap;
      justify-content: space-around;
      margin: 0 0 1rem;
    }

    .article__image-three-col .article__image-three-col-item.article__sp-2-1 {
      width: 50%;
      box-sizing: border-box;
    }

    .article__image-three-col
      .article__image-three-col-item.article__sp-2-1:nth-of-type(1) {
      padding: 10px 20px 10px 0;
    }

    .article__image-three-col
      .article__image-three-col-item.article__sp-2-1:nth-of-type(2) {
      padding: 10px 0 10px 20px;
    }

    .article__image-three-col
      .article__image-three-col-item.article__sp-2-1:nth-of-type(3) {
      padding: 10px;
    }

    .article__image-three-col .article__image-three-col-item img {
      width: 100%;
    }

    .article__image-three-col.item-2-3 {
      justify-content: space-between;
    }

    .article__image-three-col.item-2-3 .article__image-three-col-item.item-2-3 {
      width: 46%;
      padding: 0;
    }

    .article__image-three-col.item-2-3
      .article__image-three-col-item.item-2-3
      img {
      width: 100%;
      height: auto;
    }

    .article__image-four-col {
      flex-wrap: wrap;
      justify-content: space-around;
    }

    .article__image-four-col .article__image-four-col-item {
      width: 45%;
    }

    .article__column-content p.article__figurement {
      position: relative;
      font-size: 14px;
      line-height: 20px;
      min-height: 30px;
    }

    .article__column-content p.article__figurement span {
      position: absolute;
      bottom: 0;
      word-break: break-all;
    }

    .article__content-two-col .article__content-two-col-item {
      width: 46%;
    }

    .article__content-two-col-item.article__img-full {
      margin: 0 auto 1rem;
      max-width: 400px;
    }

    .article__content-two-col .article__content-two-col-item img {
      width: 100%;
      max-width: 400px;
    }

    .article__content-two-col
      .article__content-two-col-item.article__right-img {
      padding: 0;
      box-sizing: border-box;
      text-align: center;
    }
  }

  @media (max-width: 750px) {
    .article__column-inner {
      padding: 0 6%;
      box-sizing: border-box;
    }
  }

  @media (max-width: 640px) {
    .article__image-container img {
      width: 100%;
    }
  }

  @media (max-width: 350px) {
    p.article__figurement span {
      font-size: 12px;
    }
  }

  /* ==============コラム utility ============
  ============================================= */
  .mt-0 {
    margin-top: 0;
  }

  .mt-10 {
    margin-top: 10px !important;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .pt-0 {
    padding-top: 0;
  }

  .pt-10 {
    padding-top: 10px;
  }

  .pt-20 {
    padding-top: 20px;
  }

  .pb-0 {
    padding-bottom: 0;
  }

  .pb-10 {
    padding-bottom: 10px;
  }

  .pb-20 {
    padding-bottom: 20px;
  }

  h3.row-2 {
    height: 52px;
  }

  h3.row-4 {
    height: 104px;
  }

  .section-last {
    margin: 0 0 20px;
  }

  .sp-display {
    display: none;
  }

  .pc-display {
    display: block;
  }

  .ta-l {
    text-align: left;
  }

  .ta-c {
    text-align: center;
  }

  .letter-1 {
    letter-spacing: -1px;
  }

  .letter-half {
    letter-spacing: -1px;
  }

  @media (max-width: 768px) {
    .sp-display {
      display: block;
    }

    .pc-display {
      display: none;
    }
  }

  .article__link-font {
    color: #0000ee;
    word-break: break-all;
    display: block;
    margin: 0 0 20px;
  }

  .article__next-section {
    border-bottom: none;
  }
}
