@use '../../../sass/foundation/mixin';

// ==================================================
// Project
// ==================================================

// Furniture
// ==========================================================================

.Furniture {
  @include mixin.media-all-pc {
    min-height: calc(100vh - 7.6rem);
    padding-bottom: 6rem;
    padding-top: 5.8rem;
  }

  @include mixin.media-all-sp {
    min-height: calc(100vh - 10.2rem);
    padding-bottom: 8rem;
  }

  overflow-x: hidden;
  background-color: mixin.$color-lightGray;

  @at-root {
    .Furniture__inner {
      margin-right: auto;
      margin-left: auto;

      @include mixin.media-all-pc {
        width: 51.6rem;
        padding-top: 1rem;
      }

      @include mixin.media-all-sp {
        width: mixin.vw-value(674);
        padding-top: 1.2rem;
      }
    }

    .Furniture__list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &::after {
        content: '';
        display: block;
        width: 30%;
      }

      li {
        @include mixin.media-all-pc {
          width: 15.2rem;
          margin-top: 3.8rem;
        }

        @include mixin.media-all-sp {
          width: mixin.vw-value(200);
          margin-top: 1.6rem;
        }

        position: relative;

        .Furniture__item-image {
          img {
            @include mixin.media-all-pc {
              height: 15.2rem;
            }

            @include mixin.media-all-sp {
              height: mixin.vw-value(200);
            }

            border: 1px solid mixin.$color-mediumGray;
            object-fit: cover;
            cursor: pointer;
          }
        }

        .Furniture__item-menu {
          @include mixin.media-all-pc {
            top: 11.7rem;
            right: 0.6rem;
            width: 3rem;
            height: 3rem;
          }

          @include mixin.media-all-sp {
            top: 6.6rem;
            right: 0.3rem;
            width: 3.2rem;
            height: 3.2rem;
          }

          position: absolute;
          padding-top: 0.5rem;
          padding-right: 0.5rem;
          padding-left: 0.5rem;
          padding-bottom: 0.5rem;
          cursor: pointer;

          img {
            width: 100%;
            vertical-align: baseline;
          }
        }

        .Furniture__item-image-balloon {
          @include mixin.media-all-pc {
            bottom: 31.8px;
            left: -32.2px;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
          }

          @include mixin.media-all-sp {
            bottom: 3.5rem;
            left: -5.8vw;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
          }

          display: none;
          position: absolute;
          background-color: #000;
          color: #fff;
          border-radius: 0.5rem;
          z-index: 100;

          span {
            @include mixin.media-all-pc {
              width: 6em;
              padding-top: 0.6rem;
              padding-right: 1.2rem;
              padding-left: 1.2rem;
              padding-bottom: 0.6rem;
              font-size: 1.3rem;
            }

            @include mixin.media-all-sp {
              width: 5.4em;
              padding-top: 0.5rem;
              padding-right: 0.8rem;
              padding-left: 0.8rem;
              padding-bottom: 0.5rem;
              font-size: 3vw;
            }

            display: block;
            cursor: pointer;
          }
          &.-show {
            display: block;
          }

          &::after {
            @include mixin.media-all-pc {
              bottom: -2.4rem;
              left: 3.1rem;
            }

            @include mixin.media-all-sp {
              bottom: -2.3rem;
              left: 2.2rem;
            }

            content: '';
            position: absolute;
            border-top: 12px solid transparent;
            border-right: 12px solid transparent;
            border-left: 12px solid transparent;
            border-bottom: 12px solid #000;
            transform: rotate(180deg);
          }
        }

        .text-name {
          @include mixin.media-all-pc {
            margin-top: 1rem;
            font-size: 1.4rem;
          }

          @include mixin.media-all-sp {
            margin-top: mixin.vw-value(16);
            font-size: mixin.vw-value(19);
          }

          padding-left: 0.2rem;
          line-height: 1.5;
          word-wrap: break-word;
          overflow-wrap: break-word;
        }

        &.item-add {
          @include mixin.media-all-pc {
            width: 15.2rem;
            height: 15.2rem;
          }

          @include mixin.media-all-sp {
            width: mixin.vw-value(200);
            height: mixin.vw-value(200);
          }

          background-image: url('../../../../public/assets/img/furniture/add_list.svg');
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          cursor: pointer;
        }

        &.item-image-blank {
          .Furniture__item-image {
            @include mixin.media-all-pc {
              width: 15.2rem;
              height: 15.2rem;
            }

            @include mixin.media-all-sp {
              width: mixin.vw-value(200);
              height: mixin.vw-value(200);
            }

            display: block;
            background-image: url('../../../../public/assets/img/furniture/blank_image.svg');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            cursor: pointer;
          }
        }

        &.-purchase {
          position: relative;

          &::before {
            @include mixin.media-all-pc {
              font-size: 1.3rem;
            }

            @include mixin.media-all-sp {
              font-size: mixin.vw-value(18);
            }

            content: '購入予定';
            position: absolute;
            top: 0.1rem;
            left: 0.1rem;
            width: 99%;
            padding-top: 0.3rem;
            padding-bottom: 0.3rem;
            background-color: rgba(255, 255, 255, 0.7);
            text-align: center;
            font-size: 1.3rem;
            font-weight: 700;
            pointer-events: none;
          }
        }
      }
    }
  }
}
