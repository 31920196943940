#column_article.article_46 {
  .article__clear-both {
    clear: both;
  }

  .article__column-content ul {
    font-size: 22px;
    font-weight: 100;
    line-height: 40px;
  }

  .article__air_conditioning_plan-box-item1,
  .article__air_conditioning_plan-box-item2 {
    width: 50%;
  }

  .article__air_conditioning_plan-box-item1 {
    float: left;
    width: 100%;
  }

  .article__air_conditioning_plan-box-item2 {
    float: right;
    width: 100%;
  }

  /* スマートブリーズ設置例部分 */
  .article__column-content .smart_box {
    padding: 5%;
    margin: 30px 0 0;
    border: 1px solid #ccc;

    dt {
      text-align: center;
      font-size: 1.1em;
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 2px solid #ccc;

      span {
        font-size: 0.6em;
      }
    }

    dd {
      margin-bottom: 50px;
    }

    dd.end {
      margin-bottom: 0;
    }
  }

  @media (max-width: 768px) {
    .article__column-content ul {
      font-size: 14px;
      font-weight: 100;
      line-height: 20px;
    }

    .article__air_conditioning_plan-box-item1,
    .article__air_conditioning_plan-box-item2 {
      width: 100%;
    }

    .article__air_conditioning_plan-box-item1 {
      float: left;
    }

    .article__air_conditioning_plan-box-item2 {
      float: right;
    }
  }
}
