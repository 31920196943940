#column_article.article_44 {
  .article__column-content h3 {
    font-size: 19px;
    line-height: 26px;
    padding: 0 0 0 40px;
  }

  .article__column-title p {
    font-size: 23px;
  }

  .article__column-title h2 {
    font-size: 27px;
  }

  .article__column-content p {
    font-size: 20px;
    line-height: 35px;
  }

  .article__icon {
    display: flex;
    align-items: center;
  }

  .article__sp-br {
    display: none;
  }

  .article__annotation {
    font-size: 12px;
    vertical-align: super;
    padding-right: 5px;
  }

  .article__image-container {
    margin: 10px auto;
    text-align: center;
  }

  .type_box {
    width: 100%;
    overflow: hidden;
    padding-top: 20px;
    padding: 2%;
    margin-top: 20px;
    border: 1px solid #ccc;
  }

  .type_box p.text {
    font-weight: bold;
    float: left;
  }

  .type_box p.text span {
    font-size: 20px;
    font-weight: bold;
    color: red;
  }

  .type_box .type_img {
    width: 20%;
    float: right;
  }

  .article__font-bold {
    font-weight: 700;
  }

  .article__image-layout {
    width: 50%;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    .article__column-content h3 {
      font-size: 16px;
      padding: 0 0 0 25px;
      text-align: left;
      min-height: auto;
      line-height: 16px;
    }

    .article__column-title h2 {
      font-size: 18px;
    }

    .article__column-content p {
      font-size: 13px;
      line-height: 20px;
    }

    .article__column-title p {
      font-size: 14px;
    }

    .article__column-content h3.article__check::before {
      left: 0px;
    }

    .article__column-sp1
      .article__content-two-col
      .article__content-two-col-item {
      width: 100%;
    }

    .article__column-sp1
      .article__content-two-col
      .article__content-two-col-item:first-of-type {
      border-bottom: 2px solid #d6d6d6;
      margin: 0 auto;
      padding: 0 0 1rem;
    }

    .article__sp-br {
      display: block;
    }

    .article__pb-2-sp {
      padding-bottom: 20px;
    }

    .article__annotation {
      font-size: 9px;
    }

    .article__image-container {
      margin: 20px auto;
      text-align: center;
    }
  }
}
