#column_article.article_54 {
  .article__clear-both {
    clear: both;
  }

  .article__53-two-col {
    display: flex;
  }

  .article__53-float-left {
    float: left;
  }

  .article__53-float-right {
    float: right;
  }

  .article__53-two-col-item {
    width: 50%;
    padding: 5px;
  }

  .article__53-box1 {
    display: flex;
  }

  .article__53-box1-item {
    width: 100%;
    padding: 0 9px;
  }

  .article__53-box2 {
    display: flex;
  }

  .article__53-box2-item {
    width: 50%;
  }

  .article__53-box2-items img {
    width: 50%;
  }

  .article__53-box2-items p {
    align-self: flex-end;
    width: 100%;
  }

  .article__53-box3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .article__53-box3-item {
    width: 33%;
    padding: 5px;
  }

  .article__53-box4 {
    display: flex;
  }

  .article__53-box4-item {
    width: 25%;
    padding: 5px;
  }

  .article__53-box5-item {
    width: 100%;
    padding: 5px;
  }

  @media (max-width: 768px) {
    .article__53-box3-item {
      width: 50%;
    }
  }
}
