#column_article.article_27 {
  .article__font-sml.article__column-content p {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: 768px) {
    a {
      font-size: 14px;
    }

    .article__font-sml.article__column-content p {
      font-size: 9px;
      line-height: 15px;
    }
  }
}
