@import '../../../sass/foundation/mixin';

.Dialog__title.MuiTypography-root.MuiTypography-h6 {
  font-size: 1.4rem;
}

.Dialog__content {
  width: 100%;
}

.MuiSnackbar-anchorOriginTopCenter {
  @media screen and (max-width: 768px) {
    width: 32rem !important;
    margin-right: auto;
    margin-left: auto;
  }
}

.MuiSnackbarContent-root {
  justify-content: center;
  background-color: #fff !important;
  z-index: 1000;
}

.MuiSnackbarContent-message {
  position: relative;
  padding-left: 1em !important;
  color: $color-red;
  font-size: 1.6rem;
  font-weight: 700;

  &::before {
    content: '';
    position: absolute;
    top: 0.4rem;
    left: -1.6em;
    width: 2.9rem;
    height: 2.7rem;
    background-image: url('../../../../public/assets/img/common/icon/caution.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.MuiSnackbarContent-action {
  display: none !important;
}

.MuiButton-label {
  font-size: 1.2rem;
}

#alert-dialog-description {
  line-height: 2;
  white-space: pre-line;
  font-size: 1.1rem;
  font-weight: 600;
}
