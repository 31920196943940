#column_article.article_34 {
  a {
    color: #0000ee;
  }

  .article__column-content h3 {
    padding: 0 0 0 40px;
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: 768px) {
    .article__column-content h3 {
      padding: 0 0 0 35px;
      font-size: 14px;
      line-height: 14px;
      text-align: left;
    }

    .article__image-three-col .article__image-three-col-item {
      width: 100%;
    }

    .article__content-two-col
      .article__content-two-col-item.article__wid-sp100 {
      width: 100%;
      text-align: left;
    }

    a {
      font-size: 14px;
    }

    .article__image-three-col.article__sp-three-col
      .article__image-three-col-item {
      width: 30%;
    }

    .article__content-two-col .article__content-two-col-item {
      width: 100%;
    }
  }
}
