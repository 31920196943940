#column_article.article_12 {
  .article__image-three-col-item div {
    min-height: 247px;
  }

  .article__image-three-col-side {
    flex-wrap: wrap;
  }

  .article__column-title p {
    font-size: 21px;
  }

  @media (max-width: 768px) {
    .article__image-three-col-side .article__image-three-col-item {
      width: 45%;
    }

    .article__image-three-col-side
      .article__image-three-col-item:nth-of-type(1) {
      width: 37%;
    }

    .article__image-three-col-side
      .article__image-three-col-item:nth-of-type(2) {
      width: 57%;
    }

    .article__image-three-col-side
      .article__image-three-col-item:nth-of-type(3) {
      width: 48%;
    }

    .article__image-three-col-side
      .article__image-three-col-item:nth-of-type(4) {
      width: 43%;
    }

    .article__image-three-col-item div {
      min-height: auto;
    }

    .article__image-three-col-side .article__image-three-col-item p {
      margin: 1rem auto;
    }

    .article__column-title p {
      font-size: 14px;
    }
  }
}
