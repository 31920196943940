#FurnitureSlide {
  @media screen and (min-width: 769px) {
    left: -27rem;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(77, 76, 76, 0.9);
  z-index: 13000;

  .FurnitureSlide__btn {
    @media all and (min-width: 769px) {
      top: 4rem;
      right: 5rem;
      font-size: 1.8rem;
    }

    @media all and (max-width: 768px) {
      top: 6rem;
      right: 4rem;
      font-size: 1.5rem;
    }

    position: absolute;
    color: #fff;
    font-weight: bold;
  }
}

.slideshow {
  @media all and (min-width: 769px) {
    width: 80%;
    max-width: 80rem;
  }

  @media all and (max-width: 768px) {
    width: 90vw;
  }

  position: relative;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;

  input[name='slideshow'] {
    display: none;
  }
}

// slide item
.slideContents {
  display: flex;
  position: relative;
  width: 300%;
  height: 62vh;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);

  section {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 33%;

    img {
      @media all and (min-width: 1025px) {
        max-height: 62vh;
        padding-right: 4.4rem;
        padding-left: 4.4rem;
      }

      @media all and (min-width: 769px) and (max-width: 1024px) {
        max-height: 62vh;
        padding-right: 5.4rem;
        padding-left: 5.4rem;
      }

      @media all and (max-width: 768px) {
        padding-right: 5.4rem;
        padding-left: 5.4rem;
      }

      width: auto;
      max-width: 100%;
      box-sizing: border-box;
      vertical-align: middle;
      object-fit: contain;
    }

    .FurnitureSlide__count-wrap {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 4rem;
      right: 0;
      width: 100%;
    }

    .FurnitureSlide__count-text {
      @media all and (min-width: 769px) {
        font-size: 1.8rem;
      }

      @media all and (max-width: 768px) {
        font-size: 1.2rem;
      }

      padding-top: 0.8rem;
      padding-right: 1.2rem;
      padding-left: 1.2rem;
      padding-bottom: 0.8rem;
      border-radius: 2.2rem;
      background-color: rgba(0, 0, 0, 0.2);
      color: #fff;
      font-weight: 700;
      text-align: center;
    }
  }
}

// arrow
.arrow {
  @media all and (min-width: 1025px) {
    top: 28.9vh;
    width: 4.4rem;
    height: 4.4rem;
  }

  @media all and (min-width: 769px) and (max-width: 1024px) {
    top: 28.3vh;
    width: 4.4rem;
    height: 4.4rem;
  }

  @media all and (max-width: 768px) {
    top: 28.5vh;
    width: 3.8rem;
    height: 3.8rem;
  }

  position: absolute;
  margin: 0;
  transition: background 0.3s;
  cursor: pointer;

  &:hover {
    @media all and (min-width: 1025px) {
      opacity: 0.8;
    }
  }

  &.prev {
    left: 0;
    background-image: url('../../../../public/assets/img/furniture/icon/icon_prev.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.next {
    right: 0;
    background-image: url('../../../../public/assets/img/furniture/icon/icon_next.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    width: 4.4rem;
    height: 4.4rem;
    cursor: pointer;
    z-index: 1;
  }
}

// Slide mechanism
.slideContents {
  transform: translateX(-0.2%);
}

#switch1:checked ~ .slideContents {
  transform: translateX(-0.2%);
}

#switch2:checked ~ .slideContents {
  transform: translateX(-32.8%);
}

#switch3:checked ~ .slideContents {
  transform: translateX(-65.9%);
}

// Arrow mechanism
.arrow label {
  pointer-events: none;
}

.next label[for='switch2'] {
  pointer-events: auto;
}

#switch2:checked ~ .prev label[for='switch1'],
#switch3:checked ~ .prev label[for='switch2'],
#switch1:checked ~ .next label[for='switch2'],
#switch2:checked ~ .next label[for='switch3'] {
  pointer-events: auto;
}

// Hide loop arrow
.prev {
  display: none;
}

#switch2:checked ~ .prev,
#switch2:checked ~ .next,
#switch3:checked ~ .prev,
#switch1:checked ~ .next {
  display: block;
}

input:only-of-type ~ .next {
  display: none !important;
}

input:last-of-type:checked ~ .next {
  display: none !important;
}
