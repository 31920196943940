#column_article.article_08 {
  .article__mb-0 {
    margin-bottom: 0;
  }

  .article__column-content h3.mt-0 {
    margin: 0 0 1rem;
  }

  .article__column-content h3 {
    padding: 0 0px 10px 40px;
  }

  .article__image-container.article__image-width-original img:nth-of-type(1) {
    width: 61%;
    height: 100%;
    padding: 0 10px 0 0;
    box-sizing: border-box;
  }

  .article__image-container.article__image-width-original img:nth-of-type(2) {
    width: 37%;
    height: 100%;
    padding: 0 0 0 10px;
    box-sizing: border-box;
  }

  .article__image-width-original
    .article__image-some-col-item
    img:nth-of-type(1) {
    width: 60%;
    padding-right: 10px;
    box-sizing: border-box;
  }

  .article__image-width-original
    .article__image-some-col-item
    img:nth-of-type(2) {
    width: 40%;
    padding-left: 10px;
    box-sizing: border-box;
  }

  .article__content-two-col-item.article__image-width-original:nth-of-type(1) {
    width: 60%;
  }

  .article__content-two-col-item.article__image-width-original:nth-of-type(2) {
    width: 40%;
  }

  .article__content-two-col.article__cal-7-3 .article__content-two-col-item {
    width: 61%;
  }

  .article__content-two-col.article__cal-7-3
    .article__content-two-col-item.article__right-img {
    width: 39%;
  }

  .article__column-content:nth-last-of-type(4)
    .article__image-some-col-item.article__image-some-col-flex
    img:nth-of-type(1) {
    width: 60%;
  }

  .article__column-content:nth-last-of-type(4)
    .article__image-some-col-item.article__image-some-col-flex
    img:nth-of-type(2) {
    width: 40%;
  }

  .article__column-content:nth-last-of-type(3)
    .article__image-some-col-item.article__image-some-col-flex
    img:nth-of-type(1) {
    width: 60%;
  }

  .article__column-content:nth-last-of-type(3)
    .article__image-some-col-item.article__image-some-col-flex
    img:nth-of-type(2) {
    width: 40%;
  }

  .article__column-content:nth-last-of-type(2)
    .article__image-some-col-item.article__image-some-col-flex
    img:nth-of-type(1) {
    width: 60%;
  }

  .article__column-content:nth-last-of-type(2)
    .article__image-some-col-item.article__image-some-col-flex
    img:nth-of-type(2) {
    width: 40%;
  }

  .article__column-content:nth-last-of-type(1)
    .article__image-some-col-item.article__image-some-col-flex
    img:nth-of-type(1) {
    width: 60%;
  }

  .article__column-content:nth-last-of-type(1)
    .article__image-some-col-item.article__image-some-col-flex
    img:nth-of-type(2) {
    width: 40%;
  }

  p.article__text-align-right {
    text-align: right;
  }

  @media (max-width: 768px) {
    .article__content-two-col-item.article__image-width-original:nth-of-type(1),
    .article__content-two-col-item.article__image-width-original:nth-of-type(2),
    .article__content-two-col.article__cal-7-3 .article__content-two-col-item,
    .article__content-two-col.article__cal-7-3
      .article__content-two-col-item.article__right-img {
      width: 100%;
    }

    .article__image-some-col-item.article__image-some-col-flex img {
      width: auto;
    }

    .article__column-content h3.mt-0 {
      margin: 1rem 0;
    }

    .article__image-container.article__image-some-col-item.article__image-some-col-flex
      img,
    .article__image-container.article__image-some-col-item.article__image-some-col-flex.article__image-width-original
      img {
      height: 100%;
    }

    .article__mb-0 {
      margin-bottom: 20px;
    }

    .article__column-title.second {
      margin: 0;
    }
  }
}
