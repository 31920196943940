@import '../../../sass/foundation/mixin';
// 参考 https://stackoverflow.com/questions/51003189/reactjs-how-to-style-react-calendar

.react-calendar {
  width: 100%;
  margin-top: 1.4rem;
  border: none;
  .saturday {
    color: #0092ff !important;

    &.react-calendar__tile--active {
      color: #fff !important;
    }
  }
}

.react-calendar__tile abbr {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.2rem;
  height: 5.2rem;
  font-size: 1.6rem;
}

.react-calendar__tile {
  @media all and (min-width: 769px) {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  @media all and (max-width: 768px) {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #eeeeee !important;
  padding: 0;
}

.Calneder {
  // 設定したクラスに対する装飾
  // 必要な分は自分で追加

  // 以下追記分

  .react-calendar__navigation {
    @media all and (min-width: 769px) {
      margin-right: 7rem;
      margin-left: 7rem;
    }

    @media all and (max-width: 768px) {
      height: 2.4rem;
      margin-right: 2.6rem;
      margin-left: 2.6rem;
    }

    align-items: center;
    margin-bottom: 0;
  }

  .react-calendar__navigation__arrow {
    display: flex;
    justify-content: center;
    position: relative;
    visibility: hidden;

    &::before {
      @media all and (min-width: 769px) {
        width: 2.2rem;
        height: 1.2rem;
      }

      @media all and (max-width: 768px) {
        width: 1.4rem;
        height: 1.2rem;
      }
      content: '';
      position: absolute;
      top: 0;
      visibility: visible;
    }
  }

  .react-calendar__navigation__next-button {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    &::before {
      background: no-repeat center/100% url('../../../../public/media/down.svg');
    }
  }

  .react-calendar__navigation__prev-button {
    @media all and (min-width: 769px) {
      margin-top: 0.6rem;
    }

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    &::before {
      background: no-repeat center/100% url('../../../../public/media/up.svg');
    }
  }

  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__navigation__label {
    @media all and (min-width: 769px) {
      font-size: 3rem;
    }

    @media all and (max-width: 768px) {
      font-size: 1.8rem;
    }
    font-weight: 700;
    pointer-events: none;
  }

  .react-calendar__viewContainer {
    @media all and (min-width: 769px) {
      margin-top: 2rem;
    }
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    @media all and (min-width: 769px) {
      font-size: 1.6rem;
    }

    @media all and (max-width: 768px) {
      font-size: 1rem;
    }
    border-bottom: none;
    color: #6d6d6d;
    text-decoration: none;
    cursor: default;
  }

  .react-calendar__month-view__days__day abbr {
    @media all and (min-width: 769px) {
      width: 5.2rem;
      height: 5.2rem;
      font-size: 2.4rem;
    }

    @media all and (max-width: 768px) {
      width: 3.6rem;
      height: 3.6rem;
      font-size: 1.5rem;
    }

    letter-spacing: 0.18rem;
  }

  .react-calendar__month-view__days__day--weekend {
    color: $color-red;
  }

  // 日付のclassNameでつくステータス表示のスタイル
  .starday abbr {
    position: relative;
    color: #ffffff;
    z-index: 1;

    &::before {
      @media all and (min-width: 769px) {
        top: 0.5rem;
        width: 3.8rem;
        height: 3.9rem;
      }

      @media all and (max-width: 768px) {
        top: 0;
        width: 3.4rem;
        height: 3.4rem;
      }

      content: '';
      position: absolute;
      color: #ffffff;
      background: no-repeat center/100%
        url('../../../../public/media/star-48px.svg');
      z-index: -1;
    }
  }

  .red abbr {
    position: relative;
    color: #ffffff;
    z-index: 1;

    &::before {
      @media all and (min-width: 769px) {
        top: 1rem;
        width: 3.4rem;
        height: 3.7rem;
      }

      @media all and (max-width: 768px) {
        top: 0.4rem;
        width: 2.8rem;
        height: 3.2rem;
      }

      content: '';
      position: absolute;
      background: no-repeat center/100%
        url('../../../../public/media/cal_milestone_red.svg');
      z-index: -1;
    }
  }
  .red.past abbr {
    &::before {
      background: no-repeat center/100%
        url('../../../../public/media/cal_milestone_red_past.svg');
    }
  }

  .blue abbr {
    position: relative;
    color: #ffffff;
    z-index: 1;

    &::before {
      @media all and (min-width: 769px) {
        top: 0.7rem;
        width: 3.4rem;
        height: 3.7rem;
      }

      @media all and (max-width: 768px) {
        top: 0.1rem;
        width: 3rem;
        height: 3.5rem;
      }

      content: '';
      position: absolute;
      color: #ffffff;
      background: no-repeat center/100%
        url('../../../../public/media/cal_milestone_blue.svg');
      z-index: -1;
    }
  }

  .starday-red abbr {
    position: relative;
    color: #ffffff;
    z-index: 1;

    &::before {
      @media all and (min-width: 769px) {
        top: 1rem;
        width: 3.4rem;
        height: 3.7rem;
      }

      @media all and (max-width: 768px) {
        top: 0.4rem;
        width: 2.8rem;
        height: 3.2rem;
      }

      content: '';
      position: absolute;
      background: no-repeat center/100%
        url('../../../../public/media/cal_milestone_red.svg');
      z-index: -1;
    }

    &::after {
      @media all and (min-width: 769px) {
        top: 0.2rem;
        right: 0;
        width: 1.8rem;
        height: 1.8rem;
      }

      @media all and (max-width: 768px) {
        top: 0;
        right: -0.3rem;
        width: 1.5rem;
        height: 1.5rem;
      }

      content: '';
      position: absolute;
      background: no-repeat center/100%
        url('../../../../public/media/star-48px.svg');
      z-index: -1;
    }
  }
  .starday-red.past abbr {
    color: inherit;
    &::before {
      background: no-repeat center/100%
        url('../../../../public/media/cal_milestone_red_past.svg');
    }
  }

  .red-blue abbr {
    position: relative;
    color: #ffffff;
    z-index: 1;

    &::before {
      @media all and (min-width: 769px) {
        top: 1rem;
        width: 3.4rem;
        height: 3.7rem;
      }

      @media all and (max-width: 768px) {
        top: 0.4rem;
        width: 2.8rem;
        height: 3.2rem;
      }

      content: '';
      position: absolute;
      background: no-repeat center/100%
        url('../../../../public/media/cal_milestone_red.svg');
      z-index: -1;
    }

    &::after {
      @media all and (min-width: 769px) {
        top: 0.3rem;
        right: 0.2rem;
        width: 1.5rem;
        height: 1.6rem;
      }

      @media all and (max-width: 768px) {
        top: -0.1rem;
        right: 0;
        width: 1.1rem;
        height: 1.3rem;
      }

      content: '';
      position: absolute;
      background: no-repeat center/100% url('cal_milestone_blue.svg');
      z-index: -1;
    }
  }
  .red-blue.past abbr {
    color: inherit;
    &::before {
      background: no-repeat center/100%
        url('../../../../public/media/cal_milestone_red_past.svg');
    }
  }

  .today abbr {
    @media all and (min-width: 769px) {
      width: 5.2rem;
      height: 5.2rem;
    }

    border-radius: 50%;
    background-color: #dddddd;
  }

  .red-square {
    position: relative;
    z-index: 1;

    &::before {
      @media all and (min-width: 769px) {
        top: 0rem;
        width: 5.8rem;
        height: 5.8rem;
      }

      @media all and (max-width: 768px) {
        top: 0;
        width: 4.4rem;
        height: 4.4rem;
      }

      content: '';
      position: absolute;
      border-radius: 0.4rem;
      background-color: $color-pink;
      z-index: -1;
    }
  }

  .blue-square {
    position: relative;
    z-index: 1;

    &::before {
      @media all and (min-width: 769px) {
        top: 0rem;
        width: 5.8rem;
        height: 5.8rem;
      }

      @media all and (max-width: 768px) {
        top: 0;
        width: 4.4rem;
        height: 4.4rem;
      }

      content: '';
      position: absolute;
      border-radius: 0.4rem;
      background-color: $color-lightBlue;
      z-index: -1;
    }
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: none !important;

    > abbr {
      @media all and (min-width: 769px) {
        width: 5.2rem;
        height: 5.2rem;
        border-radius: 50%;
        background: #dddddd;
      }
    }
  }

  .react-calendar__tile--active {
    background: #ffffff !important;
    color: #ffffff;

    > abbr {
      @media all and (min-width: 769px) {
        width: 5.2rem;
        height: 5.2rem;
      }

      border-radius: 50%;
      background: #dddddd;
    }
  }

  .react-calendar__tile--now abbr {
    border-radius: 50%;
    background-color: #dddddd;
  }

  // 日付したに表示するテキストのスタイル
  &__tileConents {
    color: #333;

    span {
      display: block;
      width: 100%;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
}
