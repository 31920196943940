#column_article.article_32 {
  .article__image-container {
    text-align: left;
  }

  .article__column-content .article__content-two-col p {
    font-size: 14px;
    line-height: 24px;
  }

  .article__column-content .article__content-two-col p.kitchen_ti {
    padding: 5px;
    border: 1px solid #000;
    margin-bottom: 5px;
    text-align: center;
  }

  .article__column-title p {
    font-size: 20px;
  }

  .article__br-sp {
    display: none;
  }

  .article__column-content h3 {
    padding: 0 0 0 40px;
  }

  @media (max-width: 768px) {
    .article__column-content h3 {
      padding: 0 0 0 35px;
    }

    .article__br-sp {
      display: block;
    }

    .article__column-title p {
      font-size: 14px;
    }

    .article__content-two-col .article__content-two-col-item {
      width: 100%;
    }

    .article__column-title h2 {
      font-size: 16px;
    }
  }
}
