#column_article.article_20 {
  .article__content-two-col.title-area .article__content-two-col-item {
    width: 64%;
  }

  .article__content-two-col .article__content-two-col-item.article__ori-size-1 {
    width: 47%;
  }

  .article__content-two-col
    .article__content-two-col-item.article__layout:nth-of-type(1) {
    width: 80%;
  }

  .article__content-two-col
    .article__content-two-col-item.article__layout:nth-of-type(2) {
    width: 20%;
  }

  .article__content-two-col
    .article__content-two-col-item.article__original-size:nth-of-type(1) {
    width: 52%;
  }

  .article__content-two-col
    .article__content-two-col-item.article__original-size:nth-of-type(2) {
    width: 43%;
  }

  .article__column-content p.article__figurement.row-2 span {
    word-break: break-all;
  }

  .article__content-two-col-item.article__img-full img.article__icon {
    width: 70%;
  }

  .article__content-two-col .article__content-two-col-item.article__icon-title {
    width: 100%;
  }

  .article__column-content h3 {
    position: relative;
    padding: 0 0 0 40px;
  }

  .article__img_layout {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .article__column-content p.article__slm {
    font-size: 18px;
    line-height: 20px;
  }

  @media (max-width: 768px) {
    .mt-10 {
      margin-top: 10px;
    }

    .mb-10 {
      margin-bottom: 10px;
    }

    .article__image-container p {
      text-align: left;
    }

    .article__column-content p {
      margin: 10px 0;
    }

    .article__content-two-col.article__exterior-layout
      .article__content-two-col-item.article__ori-size-1 {
      width: 44%;
    }

    .article__content-two-col
      .article__content-two-col-item.article__layout:nth-of-type(1) {
      width: 70%;
    }

    .article__content-two-col
      .article__content-two-col-item.article__layout:nth-of-type(2) {
      width: 30%;
    }

    .article__column-content p.article__figurement.article__2r {
      min-height: 40px;
    }

    .article__content-two-col
      .article__content-two-col-item.article__ori-size-1 {
      width: 43%;
    }

    .mb-20-sp {
      margin-bottom: 20px;
    }

    .article__column-content .article__font-small p {
      font-size: 10px;
    }

    .article__figure.article__figure__20 {
      font-size: 17px;
    }

    .article__column-content p.article__slm {
      font-size: 8px;
      line-height: 15px;
    }

    .article__column-content p.mt-0 {
      margin-top: 0;
    }
  }
}
