#column_article.article_29 {
  .article__layout-pa {
    padding: 10px 1.5% 0;
  }

  .article__image-container {
    margin: 10px auto !important;
  }

  .article__column-content {
    padding: 20px 0 10px !important;
  }

  .article__content-two-col .article__content-two-col-item1 {
    width: 54%;
  }

  .article__content-two-col .article__content-two-col-item2 {
    width: 46%;
  }

  .article__content-two-col .article__content-two-col-item1.article__right-img,
  .article__content-two-col .article__content-two-col-item2.article__right-img {
    box-sizing: border-box;
  }

  .article__content-two-col-item1.article__img-full img,
  .article__content-two-col-item2.article__img-full img {
    width: 100%;
  }

  @media (max-width: 768px) {
    a {
      font-size: 14px;
    }

    .article__image-container {
      margin: 2px auto !important;
    }

    .article__column-content {
      padding: 0 !important;
    }

    .article__column-content h3.article__check::before {
      width: 20px;
      left: 0px;
      top: -2px;
    }

    .article__column-content h3 {
      padding: 0 0 0 25px;
      font-size: 14px;
      text-align: left;
    }
  }
}
