@use '../../../sass/foundation/mixin';

// ==================================================
// Utility
// ==================================================

@include mixin.media-all-pc {
  @for $i from 0 through 60 {
    .u-pc-mt-#{$i * 1} {
      margin-top: 1px * $i !important;
    }
    .u-pc-mr-#{$i * 1} {
      margin-right: 1px * $i !important;
    }
    .u-pc-mb-#{$i * 1} {
      margin-bottom: 1px * $i !important;
    }
    .u-pc-ml-#{$i * 1} {
      margin-left: 1px * $i !important;
    }
    .u-pc-pt-#{$i * 1} {
      padding-top: 1px * $i !important;
    }
    .u-pc-pr-#{$i * 1} {
      padding-right: 1px * $i !important;
    }
    .u-pc-pb-#{$i * 1} {
      padding-bottom: 1px * $i !important;
    }
    .u-pc-pl-#{$i * 1} {
      padding-left: 1px * $i !important;
    }
  }
}

@include mixin.media-all-sp {
  @for $i from 0 through 60 {
    .u-tb-mt-#{$i * 1} {
      margin-top: mixin.vw-value(1 * $i) !important;
    }
    .u-tb-mr-#{$i * 1} {
      margin-right: mixin.vw-value(1 * $i) !important;
    }
    .u-tb-mb-#{$i * 1} {
      margin-bottom: mixin.vw-value(1 * $i) !important;
    }
    .u-tb-ml-#{$i * 1} {
      margin-left: mixin.vw-value(1 * $i) !important;
    }
    .u-tb-pt-#{$i * 1} {
      padding-top: mixin.vw-value(1 * $i) !important;
    }
    .u-tb-pr-#{$i * 1} {
      padding-right: mixin.vw-value(1 * $i) !important;
    }
    .u-tb-pb-#{$i * 1} {
      padding-bottom: mixin.vw-value(1 * $i) !important;
    }
    .u-tb-pl-#{$i * 1} {
      padding-left: mixin.vw-value(1 * $i) !important;
    }
  }
}
