#column_article.article_16 {
  .article__column-content h3 {
    margin: 0 0 1rem;
  }

  .article__column-content p.ma-0.pl-40 {
    margin: 0;
    padding-left: 40px;
  }

  .article__content-two-col-item.article__img-full.article__ori-size2-1 {
    width: 53%;
  }

  .article__content-two-col-item.article__img-full.article__ori-size2-2 {
    width: 42%;
  }

  @media (max-width: 768px) {
    .article__content-two-col {
      justify-content: left;
    }

    .article__column-content h3 {
      margin: 1rem 0;
    }
  }
}
