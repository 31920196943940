#column_article.article_66 {
  .article__column-content p.article__font-sml {
    font-size: 18px;
    line-height: 27px;
  }

  .article__content-two-col {
    margin: 1rem 0;
  }

  @media (max-width: 768px) {
    .article__column-content p.article__font-sml {
      font-size: 9px;
      line-height: 15px;
    }

    .article__image-three-col .article__image-three-col-item {
      width: 45%;
    }

    .article__image-three-col .article__image-three-col-item:nth-of-type(3) {
      width: 100%;
    }
  }
}
