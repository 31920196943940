#column_article.article_53 {
  .article__52-two-col {
    display: flex;
  }

  .article__52-two-col-item {
    width: 50%;
    padding: 5px;
  }
}
