@use '../../../sass/foundation/mixin';

// ==================================================
// Component
// ==================================================

// Error
// ==========================================================================
.Error {
  @include mixin.media-all-pc {
    width: 100%;
    height: 100vh;
  }

  @include mixin.media-all-sp {
    width: 100%;
    height: 100vh;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: mixin.$color-lightGray;

  @at-root {
    .Error__title {
      @include mixin.media-all-pc {
        font-size: 1.8rem;
      }

      @include mixin.media-all-sp {
        font-size: mixin.vw-value(39);
      }

      position: relative;
      color: #636363;
      font-weight: 700;

      &.-icon-caution {
        @include mixin.media-all-pc {
          padding-left: 3.8rem;
        }

        @include mixin.media-all-sp {
          padding-left: mixin.vw-value(74);
        }

        &::before {
          @include mixin.media-all-pc {
            width: 3.1rem;
            height: 2.8rem;
          }

          @include mixin.media-all-sp {
            max-width: 6rem;
            width: mixin.vw-value(69);
            max-height: 5.6rem;
            height: mixin.vw-value(63);
          }

          content: '';
          position: absolute;
          top: -0.5rem;
          left: 0;
          background-image: url('../../../../public//assets/img/common/icon/caution.svg');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }

    .Error__image {
      @include mixin.media-all-pc {
        width: 10.4rem;
        height: 10.2rem;
        margin-top: 6rem;
      }

      @include mixin.media-all-sp {
        width: mixin.vw-value(231);
        max-height: 14rem;
        height: mixin.vw-value(227);
        margin-top: mixin.vw-value(110);
      }
    }

    .Error__text {
      @include mixin.media-all-pc {
        margin-top: 4.2rem;
        font-size: 1.1rem;
      }

      @include mixin.media-all-sp {
        margin-top: mixin.vw-value(90);
        font-size: mixin.vw-value(22);
      }

      color: #636363;
      text-align: center;
      line-height: 1.75;
    }

    .Error__btn {
      @include mixin.media-all-pc {
        margin-top: 3rem;
        font-size: 1.3rem;
        border-radius: 2.2rem;
      }

      @include mixin.media-all-sp {
        margin-top: mixin.vw-value(64);
        font-size: mixin.vw-value(28);
        border-radius: mixin.vw-value(44);
      }

      padding-top: 1.3rem;
      padding-right: 4rem;
      padding-left: 4rem;
      padding-bottom: 1.3rem;
      border: 2px solid mixin.$color-red;
      color: mixin.$color-red !important;
      font-weight: 700;
    }
  }
}
