#column_article.article_04 {
  .article__content-two-col.first {
    margin: 0 0 20px;
  }

  .article__column-title h2 {
    padding: 0 0px 10px 70px;
    font-size: 28px;
  }

  .article__image-three-col-item p {
    margin: 0;
    text-align: center;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    .article__column-title h2 {
      padding: 0 0px 5px 45px;
      font-size: 18px;
    }

    .article__image-three-col-item p {
      font-size: 15px;
    }

    .article__content-two-col.first {
      margin: 0;
    }

    .article__image-three-col .article__image-three-col-item {
      width: calc(100% / 3);
    }
  }
}
